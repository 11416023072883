import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  ReservesBlock,
} from "../../infra/requests/MyWeddingRequests";
import {
  Title,
  Subtitle,
  ReserveContainer,
  TableHeader,
  PricePolicy,
} from "./ReserveStyles";
import moment from "moment";
import { SpinLoading, TableButton } from "../../styles/BasicStyles";
import { ConfirmReserve, GetReserve, GetReserves, GetReservesByPlace, ReactivateReserve, SoftDeleteReserve } from "../../infra/requests/ReserveRequests";
import { locals } from "../agenda/AgendaPage";
import { TableContent } from "../agenda/ReserveInfoModal";
import Table from "../../components/table/Table";
import OrdinalNumberComponent from "../../components/ordinalNumber/OrdinalNumberComponent";
import { Content } from "../agenda/AgendaStyles";
import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import Alerts from "../../components/alert/Alert";
import CurrencyComponent from "../../components/currency/CurrencyComponent";
import DateInput from "../../components/inputs/DateInput";
import ReserveActionEmailModal from "./ReserveActionEmailModal";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import { GetWaitingDates, GetWaitingDatesPaginated, GetWeddingWaitingDates, SoftDeleteWaitingDate } from "../../infra/requests/WaintingDateRequests";
import XpertGoYearInput from "../../components/inputs/XpertGoYearInput";
import XpertGoRangePickerInput from "../../components/inputs/XpertGoRangePickerInput";
import BaseButton from "../../components/buttons/BaseButton";
import { TitleSection } from "../choices/ChoicesStyles";
import { WeddingStatus } from "./ReservePage";
import SaveButton from "../../components/buttons/SaveButton";
import { DeleteInterestDate, GetInterestDatesPaginated } from "../../infra/requests/InterestDateRequests";

class InterestListPage extends Component {
  state = {
    loading: false,
    wedding: {},
    loadingSolar: false,
    loadingLago: false,
    columns: [
      {
        title: this.props.translate('WEEKDAY'),
        titleKey: 'WEEKDAY',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return moment.utc(data.date).locale(activeLanguage.code).format('dddd')
        }
      },
      {
        title: this.props.translate('DATE'),
        titleKey: 'DATE',
        // width: '50px',
        render: data => {
          return moment.utc(data.date).format('DD-MM-YYYY')
        }
      },
      {
        title: this.props.translate("PAX"),
        titleKey: "PAX",
        render: (data) => {
          return data?.price
            ? (data?.price?.minPax || 0)
            : '';
        },
      },
      {
        title: this.props.translate("PRICE"),
        titleKey: "PRICE",
        render: (data) => {
          return data?.price
            ? <CurrencyComponent value={data?.price?.minPrice || 0} />
            : '';
        },
      },
      {
        title: this.props.translate("MINIMUM_GLOBAL_PRICE"),
        titleKey: "MINIMUM_GLOBAL_PRICE",
        render: (data) => {
          const price = data.price?.minPrice || 0;
          const pax = data.price?.minPax || 0;
          return data?.price
            ? <CurrencyComponent value={Number((price * pax).toFixed(2))} />
            : '';
        },
      },
      {
        title: this.props.translate('ACTIONS'),
        titleKey: 'ACTIONS',
        width: '200px',
        render: data => {
          const { translate } = this.props;
          return <TableButton onClick={e => e.stopPropagation()}>
            <Popconfirm
              placement="topRight"
              title={this.getDeleteMessage()}
              okText={translate('DELETE')}
              cancelText={translate('CANCEL')}
              onConfirm={async () => await this.deleteInterestDate(data)}>
              <Icon type="delete" />
              &nbsp;{translate('REMOVE_INTEREST_DATE')}
            </Popconfirm>
          </TableButton>
        }
      }
    ],
    rowsSolar: [],
    pageSizeSolar: 15,
    currentPageSolar: 1,
    totalSolar: 0,
    filtersSolar: { weddingPlace: 1 },
    dateSolar: null,
    rowsLago: [],
    pageSizeLago: 15,
    filtersLago: { weddingPlace: 2 },
    dateLago: null,
    currentPageLago: 1,
    totalLago: 0,

    dateRangeLago: [],
    dateRangeSolar: [],
    yearLago: null,
    yearSolar: null,

    showActionEmailModal: false,
    dataActionEmail: null,
    loadingActionEmail: false,
  };

  componentDidMount = () => {
    const { location, wedding } = this.props;

    document.body.style.overflowY = "auto";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    this.loadComponent();
  };

  componentDidUpdate(prevProps) {
    const { translate, activeLanguage } = this.props;
    let { columns } = this.state;

    // If activeLanguage changes, then update column titles in table
    if (prevProps.activeLanguage.code !== activeLanguage.code) {
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        if (column.titleKey) column.title = translate(column.titleKey);
      }
      this.setState({ columns });
    }
  }

  loadComponent = async () => {
    const { translate } = this.props;
    const { wedding } = this.state;

    await this.updateDatatableSolar();
    await this.updateDatatableLago();
    this.setState({ loading: false });
  }

  updateDatatableSolar = async () => {
    try {
      this.setState({ loadingSolar: true });
      const { filtersSolar, dateRangeSolar, yearSolar, currentPageSolar, pageSizeSolar } = this.state;

      if (Array.isArray(dateRangeSolar) && dateRangeSolar.length > 0) filtersSolar['dates'] = dateRangeSolar;
      else delete filtersSolar?.dates;

      if (yearSolar) filtersSolar['year'] = yearSolar;
      else delete filtersSolar?.year;

      const result = await GetInterestDatesPaginated(currentPageSolar, pageSizeSolar, JSON.stringify(filtersSolar));
      // console.warn(`result solar`, result);

      const rows = result?.success && result?.data?.items ? result.data?.items : [];

      this.setState({
        loadingSolar: false,
        rowsSolar: rows,
        totalSolar: result?.data?.total || 0,
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingSolar: false });
    }
  }

  updateDatatableLago = async () => {
    try {
      this.setState({ loadingLago: true });
      const { filtersLago, dateRangeLago, yearLago, currentPageLago, pageSizeLago } = this.state;

      if (Array.isArray(dateRangeLago) && dateRangeLago.length > 0) filtersLago['dates'] = dateRangeLago;
      else delete filtersLago?.dates;

      if (yearLago) filtersLago['year'] = yearLago;
      else delete filtersLago?.year;

      const result = await GetInterestDatesPaginated(currentPageLago, pageSizeLago, JSON.stringify(filtersLago));
      // console.warn('result lago', result);

      const rows = result?.success && result?.data?.items ? result.data?.items : [];

      this.setState({
        loadingLago: false,
        rowsLago: rows,
        totalLago: result?.data?.total || 0
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingLago: false });
    }
  }

  handleChangePage = (currentPage, weddingPlace) => {
    const data = {};
    if (weddingPlace === 2) data['currentPageLago'] = currentPage;
    else data['currentPageSolar'] = currentPage;
    this.setState(data, weddingPlace === 2 ? this.updateDatatableLago : this.updateDatatableSolar);
  };

  handleChangeRowsPerPage = (currentSize, pageSize, weddingPlace) => {
    const data = {};
    if (weddingPlace === 2) {
      data['pageSizeLago'] = pageSize;
      data['currentPageLago'] = 1;
    } else {
      data['currentPageSolar'] = 1;
      data['pageSizeSolar'] = pageSize;
    }
    this.setState(data, weddingPlace === 2 ? this.updateDatatableLago : this.updateDatatableSolar);
  };


  /** Messages for PopConfirms on Table */
  getDeleteMessage = () => {
    const { translate } = this.props;

    return <div>
      <div>{translate('DELETE_INTEREST_DATE_QUESTION')}</div>
      <div>{translate('DELETE_INTEREST_DATE_INFO')}</div>
    </div>
  }
  /** end Messages for PopConfirms on Table */

  deleteInterestDate = async (interestDate) => {
    const { translate, dispatch } = this.props;

    const result = await DeleteInterestDate(interestDate?._id, true);

    if (result?.success) {
      if (interestDate?.weddingPlace == 2) this.updateDatatableLago()
      else this.updateDatatableSolar();
    } else {
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate(result?.data)
      });
    }
  }

  goToAgenda = () => {
    const { history } = this.props;
    history.push('/interest-agenda');
  }

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { wedding } = this.props;
    const { loading, loadingLago, loadingSolar } = this.state;
    const { columns, rowsSolar, rowsLago } = this.state;
    const { currentPageSolar, currentPageLago, pageSizeSolar, pageSizeLago, totalLago, totalSolar } = this.state;
    const { dateRangeLago, dateRangeSolar, yearLago, yearSolar } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <Content>
        <ReserveContainer>
          <Title>{translate("INTEREST_DATE_LIST")}</Title>

          {locals.map((local, index) => (
            <TableContent key={index} first={index === 0}>
              <TableHeader first={index === 0}>
                <Subtitle>{translate("INTEREST_LIST_FOR")} {local.name}</Subtitle>
              </TableHeader>
              <Row gutter={24} style={{ marginBottom: 10 }}>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <XpertGoRangePickerInput
                    label={translate('DATE_RANGE')}
                    allowClear={true}
                    placeholder={translate('SELECT_DATE_RANGE')}
                    input={{
                      value: local?._id == 2 ? dateRangeLago : dateRangeSolar,
                      onChange: value => {
                        const valueRange = Array.isArray(value) && value.length > 0
                          ? value
                          : [];
                        if (local._id === 2) {
                          this.setState({ dateRangeLago: valueRange, yearLago: null },
                            () => this.updateDatatableLago());
                        } else {
                          this.setState({ dateRangeSolar: valueRange, yearSolar: null },
                            () => this.updateDatatableSolar());
                        }
                      }
                    }}
                    meta={{ valid: true }}
                    disabled={loading}
                    first={true}
                  />
                </Col>
                <Col xs={24} sm={5} md={5} lg={3} xl={2}>
                  <XpertGoYearInput
                    label={translate('YEAR')}
                    disabled={loading}
                    placeholder={translate('SELECT_YEAR')}
                    input={{
                      value: local?._id == 2 ? yearLago : yearSolar,
                      onChange: value => {
                        if (local._id === 2) {
                          this.setState({ dateRangeLago: [], yearLago: value },
                            () => this.updateDatatableLago());
                        } else {
                          this.setState({ dateRangeSolar: [], yearSolar: value },
                            () => this.updateDatatableSolar());
                        }
                      }
                    }}
                    meta={{ valid: true }}
                    first={true}
                  />
                </Col>
                <Col xs={24} sm={7} md={13} lg={15} xl={16}>
                  <PricePolicy>
                    <Tooltip title={this.props.translate('INFO_PRICE_VALIDITY')}>
                      <Icon type="info-circle" style={{ marginRight: 8 }} />
                      {this.props.translate('PRICE_POLICY')}
                    </Tooltip>
                  </PricePolicy>
                </Col>
              </Row>

              <Table
                loading={loading || (local._id === 2 ? loadingLago : loadingSolar)}
                currentPage={local._id === 2 ? currentPageLago : currentPageSolar}
                pageSize={local._id === 2 ? pageSizeLago : pageSizeSolar}
                defaultPageSize={local._id === 2 ? pageSizeLago : pageSizeSolar}
                total={local._id === 2 ? totalLago : totalSolar}
                columns={columns}
                rows={local._id === 2 ? rowsLago : rowsSolar}
                showHeader={true}
                emptyText={translate('NO_INTEREST_DATES_BY_SPACE')}
                rowKey={'_id'}
                hasPagination={true}
                handleChangePage={(currentPage) => this.handleChangePage(currentPage, local._id)}
                handleChangeRowsPerPage={(currentSize, pageSize) => this.handleChangeRowsPerPage(currentSize, pageSize, local._id)}
              />

              <SaveButton
                htmlType="button"
                text={translate("MNG")}
                textMobile={translate("MNG")}
                onClick={this.goToAgenda}
              />
            </TableContent>
          ))}
        </ReserveContainer>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

export default withLocalize(
  withRouter(connect(mapStateToProps)(InterestListPage))
);
