import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { SpinLoading } from "../../styles/BasicStyles";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import {
  Content,
  Container,
  Title,
  Legend,
  LegendItem,
  ReserveContent,
  ReserveTitle,
  ReserveSubtitle,
  ReserveList,
  ReserveItem,
  Loading
} from "./AgendaStyles";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import BaseButton from "../../components/buttons/BaseButton";
import moment from "moment";
import Alerts from "../../components/alert/Alert";
import { Button, Checkbox, Col, Dropdown, Icon, Menu, Modal, Popconfirm, Radio, Row, Tooltip } from "antd";
import { CalendarBox, CalendarPicker, LinkAcceptPolicy, RadioPlace, RadioYear } from "../../components/YearView/CalendarStyles";
import YearView from "../../components/YearView/YearView";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import YearInput from "../../components/inputs/YearInput";
import XpertGoYearInput from "../../components/inputs/XpertGoYearInput";
import LoadingIcon from "../../assets/icons/loading.svg";
import SaveButton from "../../components/buttons/SaveButton";
import ReserveInfoModal from "./ReserveInfoModal";
import { GetWedding, GetWeddingInfo, ReservesBlock, UpdateInterestInfo, UpdateReserveRelatedInfo, UpdateWaitingDatePeriodNotification, UpdateWaitingInfo } from "../../infra/requests/MyWeddingRequests";
import DateWarningModal from "./DateWarningModal";
import { ReserveStatus, WeddingStatus } from "../reserve/ReservePage";
import XpertGoLeavePageBlocker from "../../components/pageBlocker/XpertGoLeavePageBlocker";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import { MngMultipleReserves, GetReserveAgenda, GetWeddingReserves, GetReserve, GetReservesWithOrder, CheckDatesAvailable } from "../../infra/requests/ReserveRequests";
import { CreateMultipleWaitingDate, CreateWaitingDate, GetWeddingWaitingDates, SoftDeleteMultipleWaitingDate, SoftDeleteWaitingDate, softDeleteWaitingDateAgenda } from "../../infra/requests/WaintingDateRequests";
import { WaitingIcon } from "../../components/inputs/InputStyles";
import Bookmark from "../../assets/icons/bookmark.svg";
import ReservesIcon from "../../assets/icons/reserves_white.svg";
import InterestListIcon from "../../assets/icons/interest_list_white.svg";
import VisitsIcon from "../../assets/icons/visit_list_white.svg";
import WaitingListIcon from "../../assets/icons/waiting_list_white.svg";
import AgendaIcon from "../../assets/icons/calendar.svg";
import Star from "../../assets/icons/star.svg";
import { CreateInterestDate, DeleteInterestDateAgenda, GetWeddingInterestDates } from "../../infra/requests/InterestDateRequests";
import WaitingInterestPolicyModal from "./WaitingDatesPolicyModal";
import UpButton from "../../components/buttons/UpButton";
import { IconAgenda, IconImg } from "../../components/header/HeaderStyles";
import { StyledButton } from "../../components/buttons/ButtonsStyles";
import styledComponents from "styled-components";
import { VisitsBlockedType } from "./VisitAgendaPage";
import { inAgendaTest } from "../../infra/services/utils/Helpers";
import InterestPolicyModal from "./InterestPolicyModal";
import WaitingDatesPolicyModal from "./WaitingDatesPolicyModal";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'
// localizer.messages.year = 'Year'

const RowButtons = styledComponents.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const locals = [
  { _id: 1, name: 'Solar da Levada' },
  { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

export const WaitingActionType = {
  MONTH: 'M', // M: Month
  WEEKDAY: 'WD', // WD: Weekday
  WEEK: 'W', // W: week
  ALL: 'A', // A: All
  DAY: 'D', // D: Day
};

export const AgendaType = {
  AGENDA: 'agenda', // Reserve Agenda
  WAITING_DATE: 'waiting-agenda', // Waiting Dates Agenda
  INTEREST: 'interest-agenda', // Interest Dates Agenda
  VISIT: 'visit-agenda', // Schedule Agenda
};

class AgendaPage extends Component {
  state = {
    loading: true,
    content: {},

    agendaType: AgendaType.AGENDA,
    showAgenda: false,
    loadingAgenda: false,
    year: moment.utc().year(),
    yearFilter: null,
    local: 1,
    dataSource: [],
    selectingDay: false,

    waitingDatesLoading: false,
    waitingDates: [],
    selectingWaitingDate: false,

    interestDatesLoading: false,
    interestDates: [],
    selectingInterestDate: false,

    reservesLoading: false,
    reserveList: [],
    removeReserveList: [],

    editReserve: null,
    wedding: null,

    acceptedWaitingPolicy: false,
    untilPeriodNotificationWaitingDate: 2,
    savingWeddingWaiting: false,
    showWaitingPolicyModal: false,

    acceptedInterestPolicy: false,
    savingWeddingInterest: false,
    showInterestPolicyModal: false,

    showReserveModal: false,
    valuesReserveModal: {},

    showDateModal: false,
    dataDateModal: null,

    showConfirmModal: false,
    canActionOnReserves: false,
  };

  componentDidMount() {
    const { location, translate, history } = this.props;
    const { wedding } = this.props;
    let { agendaType } = this.state;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    const waitingUrl = location?.pathname && location?.pathname === '/waiting-agenda' ? true : false;
    const interestUrl = location?.pathname && location?.pathname === '/interest-agenda' ? true : false;
    const agendaUrl = location?.pathname && location?.pathname === '/agenda' ? true : false;
    if (waitingUrl) {
      agendaType = AgendaType.WAITING_DATE;
      Modal.info({
        title: translate('ATTENTION'),
        content: translate('WAITING_DATES_INFO'),
        onOk: () => { }
      });
    } else if (interestUrl) {
      agendaType = AgendaType.INTEREST;
      if (wedding?.status && wedding?.status !== WeddingStatus.RESERVE) {
        history.push('/404');
        return;
      } else if (!wedding?.acceptedInterestPolicy) {
        this.setState({ showInterestPolicyModal: true })
      } else {
        Modal.info({
          title: translate('ATTENTION'),
          content: translate('INTEREST_DATES_INFO'),
          onOk: () => { }
        });
      }
    } else {
      agendaType = AgendaType.AGENDA;
    }

    const reserveId = new URLSearchParams(location.search).get('reserve') !== undefined
      ? new URLSearchParams(location.search).get('reserve')
      : null;

    this.checkActionOnReserves();

    this.setState({
      agendaType,
      year: agendaType == AgendaType.INTEREST ? moment.utc().year() + 1 : moment.utc().year(),
      showAgenda: agendaType !== AgendaType.AGENDA || reserveId ? true : false,
      loading: agendaType !== AgendaType.AGENDA || reserveId ? false : true,
      wedding,
    }, async () => agendaType !== AgendaType.AGENDA
      ? this.getAgenda(false, true)
      : reserveId
        ? this.getReserve(reserveId)
        : this.getContent());
  }

  componentDidUpdate(prevProps, props) {
    const oldReserveId = new URLSearchParams(prevProps.location.search).get('reserve') !== undefined
      ? new URLSearchParams(prevProps.location.search).get('reserve')
      : null;
    const newReserveId = new URLSearchParams(prevProps.history.location.search).get('reserve') !== undefined
      ? new URLSearchParams(prevProps.history.location.search).get('reserve')
      : null;
    if (oldReserveId && !newReserveId) {
      this.cancel();
    }
  }

  getContent = async () => {
    const { history, match: { params }, activeLanguage } = this.props;

    const { data } = await GetDynamicPage('63bee23558f9a5503e210824');

    if (!data) {
      history.push('/404');
      return false;
    }

    this.setState({
      content: data,
      loading: false,
    });
  };

  presentAgenda = () => {
    this.setState({ showAgenda: true }, () => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflowY = 'auto';
      this.getAgenda();
    });
  }

  checkActionOnReserves = async () => {
    // Check if any actions can still be made to this wedding reserves
    const resultCheck = await CheckReservesActions();
    // console.warn('resultCheck', resultCheck);
    this.setState({ canActionOnReserves: resultCheck?.success ? true : false });
  }

  getAgenda = async (fromReserve = false, changeAgenda = true) => {
    const { history } = this.props;
    const { year, local, agendaType } = this.state;
    let { reserveList, editReserve } = this.state;

    if (fromReserve) this.setState({ reservesLoading: true });
    else this.setState({ loadingAgenda: true, reservesLoading: changeAgenda });

    try {
      const filters = { year, local };
      if (agendaType == AgendaType.INTEREST) {
        filters['agendaType'] = 'INTEREST';
      }
      const result = await GetReserveAgenda(filters);
      const data = result && result.data ? result.data : [];

      if (agendaType === AgendaType.WAITING_DATE) await this.getWaitingDates();
      else if (agendaType === AgendaType.INTEREST) await this.getInterestDates();

      const resultWedding = await GetWeddingInfo();

      if (resultWedding?.success && agendaType === AgendaType.INTEREST && resultWedding?.data?.status !== WeddingStatus.RESERVE) {
        history.push('/404');
        return;
      }

      const resetData = data.filter(f => f?.resource?.selected || f?.resource?.edit);
      if (resetData?.length > 0) {
        for (let index = 0; index < resetData.length; index++) {
          resetData[index].resource.selected = false;
          resetData[index].resource.edit = false;
        }
      }

      if (changeAgenda) {
        const resultReserve = await GetWeddingReserves(JSON.stringify(filters));
        // console.warn('resultReserve', resultReserve);
        const reserves = resultReserve.success && resultReserve.data ? resultReserve.data : [];
        if (reserves?.length > 0) {
          reserves.forEach((reserve) => {
            const index = reserveList.findIndex(f => reserve?.event?.id && f?.event?.id && f.event.id === reserve?.event?.id && reserve?.weddingPlace === f.weddingPlace);
            if (index === -1) {
              reserveList.push(reserve);
            }
          });
        }
      }

      // Select dates that are in reserve list
      reserveList.forEach((reserve) => {
        const index = data.findIndex(f => reserve?.event && f.id === reserve?.event?.id && reserve?.weddingPlace === local);
        if (index > -1) {
          data[index].resource.selected = true;

          if ((editReserve?.event && reserve?.event?.id === editReserve?.event?.id) ||
            (!editReserve?.event && reserve?.event?.resource?.weddingReserve && reserve?.event?.resource?.weddingReserve?._id === editReserve?._id)) {
            data[index].resource.edit = true;
            editReserve = reserve;
          }
        }
      });

      const wedding = resultWedding?.success && resultWedding?.data ? resultWedding.data : this.props.wedding;
      this.setState({
        dataSource: data,
        reserveList,
        editReserve,
        loadingAgenda: false,
        reservesLoading: false,
        wedding,
        acceptedWaitingPolicy: wedding?.acceptedWaitingPolicy || false,
        untilPeriodNotificationWaitingDate: wedding?.untilPeriodNotificationWaitingDate || 2,
        acceptedInterestPolicy: wedding?.acceptedInterestPolicy || false,
      });
    } catch (e) {
      this.setState({ loadingAgenda: false, reservesLoading: false });
      console.error(e);
    }
  }

  getWaitingDates = async () => {
    const { year, local } = this.state;
    const filters = { year, local };
    this.setState({ waitingDatesLoading: true });

    const resultWaitingDates = await GetWeddingWaitingDates(JSON.stringify(filters));

    this.setState({
      waitingDates: resultWaitingDates?.success && resultWaitingDates?.data ? resultWaitingDates?.data : [],
      waitingDatesLoading: false
    });
  }

  getInterestDates = async () => {
    const { year, local } = this.state;
    const filters = { year, local };
    this.setState({ interestDatesLoading: true });

    const resultInterestDates = await GetWeddingInterestDates(JSON.stringify(filters));

    this.setState({
      interestDates: resultInterestDates?.success && resultInterestDates?.data ? resultInterestDates?.data : [],
      interestDatesLoading: false
    });
  }

  getReserve = async (reserveId) => {
    const { history } = this.props;
    const { year } = this.state;

    this.setState({ loadingAgenda: true });
    const result = await GetReserve(reserveId);
    // console.warn('result', result);

    if (result.success) {
      const reserveYear = result?.data?.date && moment.utc(result?.data?.date).isValid()
        ? moment.utc(result?.data?.date).year()
        : null;

      this.setState({
        editReserve: result?.data,
        wedding: result?.data?.wedding,
        acceptedWaitingPolicy: result?.data?.wedding?.acceptedWaitingPolicy || false,
        untilPeriodNotificationWaitingDate: result?.data?.wedding?.untilPeriodNotificationWaitingDate || 2,
        local: result?.data?.weddingPlace,
        year: reserveYear && reserveYear !== year ? reserveYear : year
      }, () => this.getAgenda(true, true));
    } else {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: result?.data
      });
      this.setState({
        editReserve: null,
        wedding: null,
        acceptedWaitingPolicy: false,
        untilPeriodNotificationWaitingDate: 2
      }, () => {
        history.push('/agenda');
        this.getAgenda();
      });
    }
  }

  changeTab = (event) => {
    this.setState({ local: event?.target?.value }, () => this.getAgenda(false, false));
  }

  changeYear = (event) => {
    const { year } = this.state;
    this.setState({ year: (year + parseInt(event.target.value)) }, () => this.getAgenda(false, false));
  }

  /** Waiting Dates */
  onMultipleWaitingClick = async (value) => {
    const { translate } = this.props;
    const { wedding, local, year } = this.state;
    // console.warn('value', value);

    if (!wedding?.acceptedWaitingPolicy) {
      Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_MISSING_WAITING_POLICY')
      });
      return;
    }

    this.setState({ selectingWaitingDate: true });

    if (value?.month >= 0) {
      if (moment.utc().set('year', year).set('month', value?.month).startOf('month').isBefore(moment.utc().startOf('month'), 'date')) {
        Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('ERROR_WAITING_BEFORE_TODAY')
        });
        this.setState({ selectingWaitingDate: false });
        return;
      }

      const data = {
        type: value.type,
        weekday: value?.weekday >= 0 ? value.weekday : null,
        year,
        month: value.month,
        weddingPlace: local
      };

      const result = value?.selected
        ? await SoftDeleteMultipleWaitingDate(true, data)
        : await CreateMultipleWaitingDate(data);
      if (result?.success) {
        const translateStr = value?.selected
          ? 'REMOVED_WAITING_DATES'
          : 'ADDED_WAITING_DATES';
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate(translateStr)
        });
        this.setState({
          selectingWaitingDate: false,
          waitingDatesLoading: true
        }, () => this.getWaitingDates());
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: result?.data ? translate(result?.data) : translate('GENERIC_ERROR')
        });
        this.setState({ selectingWaitingDate: false });
      }
    }
  }

  onWaitingClick = async (value) => {
    const { translate } = this.props;
    const { wedding, local } = this.state;
    // console.warn('value', value);
    if (!wedding?.acceptedWaitingPolicy) {
      Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_MISSING_WAITING_POLICY')
      });
      return;
    }

    this.setState({ selectingWaitingDate: true });

    if (value?.date) {
      if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
        Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('ERROR_WAITING_DATE_BEFORE_TODAY')
        });
        this.setState({ selectingWaitingDate: false });
        return;
      }

      const data = {
        type: WaitingActionType.DAY,
        date: value?.date,
        weddingPlace: local
      };
      const result = value?.selected
        ? await softDeleteWaitingDateAgenda(true, data)
        : await CreateWaitingDate(data);
      if (result?.success) {
        const translateStr = value?.selected
          ? 'REMOVED_WAITING_DATE'
          : 'ADDED_WAITING_DATE';
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate(translateStr)
        });
        this.setState({
          selectingWaitingDate: false,
          waitingDatesLoading: true
        }, () => this.getWaitingDates());
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: result?.data ? translate(result?.data) : translate('GENERIC_ERROR')
        });
        this.setState({ selectingWaitingDate: false });
      }
    }
  }

  /** Interest Dates */
  onInterestClick = async (value) => {
    const { translate } = this.props;
    const { wedding, local } = this.state;
    // console.warn('value', value);

    if (!wedding?.acceptedInterestPolicy) {
      Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_MISSING_INTEREST_POLICY')
      });
      return;
    }

    this.setState({ selectingInterestDate: true });

    if (value?.date) {
      if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
        Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('ERROR_INTEREST_DATE_BEFORE_TODAY')
        });
        this.setState({ selectingInterestDate: false });
        return;
      }

      const data = {
        date: value?.date,
        weddingPlace: local,
        price: value?.event?.resource?.price
      };
      const result = value?.selected
        ? await DeleteInterestDateAgenda(true, data)
        : await CreateInterestDate(data);
      if (result?.success) {
        const translateStr = value?.selected
          ? 'REMOVED_INTEREST_DATE'
          : 'ADDED_INTEREST_DATE';
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate(translateStr)
        });
        this.setState({
          selectingInterestDate: false,
          interestDatesLoading: true
        }, () => this.getInterestDates());
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: result?.data ? translate(result?.data) : translate('GENERIC_ERROR')
        });
        this.setState({ selectingInterestDate: false });
      }
    }
  }

  /** Reserve Dates */
  onDayClick = async (value) => {
    const { translate } = this.props;
    const { wedding, canActionOnReserves } = this.state;
    const { local, reserveList, editReserve } = this.state;
    let { dataSource } = this.state;
    this.setState({ selectingDay: true });
    // console.warn('value', value);

    // Check if wedding/couple is in reserve phase
    if (!wedding || (wedding?.status && wedding?.status !== WeddingStatus.RESERVE)) {
      return;
    }

    if (value?.date && moment.utc(value?.date).isBefore(moment.utc().startOf('date'))) {
      Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_RESERVES_BEFORE_TODAY')
      });
      return;
    }

    // If wedding.reservesBlocked then all reserves action are blocked
    if ((wedding && wedding?.reservesBlocked)) return;

    // Edit date selected
    if (editReserve) {
      //Check if can edit selected date by local
      const reservesByLocal = reserveList.filter(f => f?.weddingPlace && f?.weddingPlace === local && editReserve && editReserve.event && f.event.id !== editReserve.event.id);
      if (reservesByLocal.length >= 2) {
        return Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('NUMBER_RESERVES_BY_PLACE')
        });
      }

      //Check if the selected date in already in reserve list
      const index = reserveList?.findIndex(f => f?.weddingPlace && f?.weddingPlace === local && value && value.event && f.event.id === value.event.id);
      if (index > -1) {
        return Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('RESERVE_DATE_SELECTED')
        });
      }

      this.setState({
        selectingDay: false,
        showDateModal: true,
        dataDateModal: {
          original: editReserve,
          new: { ...value, weddingPlace: local },
        }
      });
    } else {
      //Check if can add selected date by local
      const reservesByLocal = reserveList.filter(f => f?.weddingPlace && f.weddingPlace === local);
      if (reservesByLocal.length >= 2) {
        return Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('NUMBER_RESERVES_BY_PLACE')
        });
      }

      this.setState({ reservesLoading: true });

      const index = dataSource?.findIndex(f => value?.event && f?.id === value?.event?.id);
      if (index > -1) {
        value.event.resource.selected = true;
        dataSource[index] = value?.event;

        const indexReserve = reserveList.findIndex(f => f?.event && value?.event && f?.event?.id === value?.event?.id && f?.weddingPlace === local);
        if (indexReserve === -1) reserveList.push({ ...value, weddingPlace: local });
      }
      this.setState({ reserveList, dataSource }, () => {
        setTimeout(() => this.setState({ reservesLoading: false, selectingDay: false }), 200);
      });
    }
  }

  getRemoveMessage = () => {
    const { translate } = this.props;

    return <div>
      <div>{translate('DELETE_RESERVE_QUESTION')}</div>
      <div>{translate('DELETE_RESERVE_INFO')}</div>
    </div>
  }

  // Reserve List Actions
  editDate = (reserve) => {
    let { dataSource, year } = this.state;
    // console.warn('reserve', reserve);

    const indexData = dataSource.findIndex(f => f?.id === reserve?.event?.id);
    if (indexData > -1) {
      reserve.event.resource.edit = true;
      dataSource[indexData] = reserve.event;
    }

    const reserveYear = reserve?.date && moment.utc(reserve.date).isValid()
      ? moment.utc(reserve.date).year()
      : null;

    this.setState({
      dataSource,
      editReserve: reserve,
      local: reserve?.weddingPlace,
      year: reserveYear && reserveYear !== year ? reserveYear : year
    }, () => this.getAgenda(false, false));
  }

  cancelEditDate = (reserve) => {
    let { dataSource } = this.state;
    // console.warn('reserve', reserve);

    const indexData = dataSource.findIndex(f => f?.id === reserve?.event?.id);
    if (indexData > -1) {
      reserve.event.resource.edit = false;
      dataSource[indexData] = reserve.event;
    }

    this.setState({ dataSource, editReserve: null, local: reserve?.weddingPlace }, () => this.getAgenda(false, false));
  }

  editReserveDate = (value) => {
    const { local, editReserve } = this.state;
    let { dataSource, reserveList } = this.state;

    this.setState({ showDateModal: false, dataDateModal: null, reservesLoading: true });

    // Unselect editReserve date
    const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
    if (indexEdit > -1) {
      editReserve.event.resource.selected = false;
      editReserve.event.resource.edit = false;
      dataSource[indexEdit] = editReserve.event;
    }

    // Select new date
    const indexData = dataSource.findIndex(f => value && value.event && f?.id === value.event?.id);
    if (indexData > -1) {
      value.event.resource.selected = true;
      dataSource[indexData] = value.event;
    }

    // Update reserve list with new date information
    const indexReserve = reserveList.findIndex(f => f?.event && editReserve?.event && f?.event?.id === editReserve?.event?.id && f?.weddingPlace === editReserve?.weddingPlace);
    if (indexReserve > -1) {
      const weddingReserve = reserveList[indexReserve].event && reserveList[indexReserve].event.resource && reserveList[indexReserve].event.resource.weddingReserve ? reserveList[indexReserve].event.resource.weddingReserve : null;
      const updateReserve = { ...value, weddingPlace: local };
      if (weddingReserve && updateReserve && updateReserve.event && updateReserve.event.resource) updateReserve.event.resource['weddingReserve'] = weddingReserve;
      // console.warn('updateReserve', updateReserve);
      reserveList[indexReserve] = updateReserve;
    }

    this.setState({
      reserveList,
      dataSource,
      editReserve: null,
    }, () => {
      setTimeout(() => this.setState({ reservesLoading: false }), 200);
    });
  }

  removeDate = (reserve) => {
    this.setState({ reservesLoading: true });
    let { reserveList, removeReserveList, dataSource } = this.state;
    const indexData = dataSource?.findIndex(f => f.id === reserve?.event?.id);
    if (indexData > -1) {

      reserve.event.resource.selected = false;
      reserve.event.resource.edit = false;
      dataSource[indexData] = reserve.event;
    }

    const indexReserve = reserveList.findIndex(f => f?.event && reserve?.event && f?.event?.id === reserve?.event?.id && f?.weddingPlace === reserve?.weddingPlace);
    if (indexReserve > -1) {
      // if reserve that it's already in database add to removeReserveList
      if (reserveList[indexReserve] && reserveList[indexReserve]?.event && reserveList[indexReserve]?.event?.resource && reserveList[indexReserve]?.event?.resource?.weddingReserve) {
        const indexDelete = removeReserveList.findIndex(f => f?._id === reserveList[indexReserve].event.resource.weddingReserve?._id);
        if (indexDelete === -1) removeReserveList.push(reserveList[indexReserve].event.resource.weddingReserve);
      }

      reserveList.splice(indexReserve, 1);
    }

    this.setState({ reserveList, dataSource, removeReserveList }, () => {
      setTimeout(() => this.setState({ reservesLoading: false }), 200);
    });
  }

  // Create Reserve
  finishReserve = async (values) => {
    const { history, translate, wedding } = this.props;
    const { reserveList, removeReserveList } = this.state;

    // if ((!values?.visitedPlace || values?.visitedPlace == false)
    //   && (!values?.placeToVisit || values?.placeToVisit == false)
    //   && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
    //   Alerts.new({
    //     type: 'warning',
    //     title: translate('ATTENTION'),
    //     text: translate('VISIT_INFO_WARNING')
    //   });
    //   return;
    // }

    // Check if any actions can still be made to this wedding reserves
    const resultCheck = await CheckReservesActions(reserveList);
    if (!resultCheck?.success) {
      Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('LIMIT_ACTION_RESERVES')
      });
      return;
    }

    // Check if all dates selected are still available
    const dataCheck = {
      wedding,
      reserves: reserveList,
      removeReserves: removeReserveList
    };
    const resultCheckDates = await CheckDatesAvailable(dataCheck);
    if (!resultCheckDates?.success) {
      if (resultCheckDates?.data === 'ERROR_DATE_OCCUPIED' || resultCheckDates?.data === 'ERROR_DATE_UNAVAILABLE'
        || resultCheckDates?.data === 'DUPLICATED_DATES' || resultCheckDates?.data === 'NUMBER_RESERVES_BY_PLACE') {
        Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate(resultCheckDates.data)
        });
        // window.location.reload(true);
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: resultCheckDates?.data
        });
      }
      return;
    }

    const resultWedding = await UpdateReserveRelatedInfo(values);
    // console.warn('resultWedding', resultWedding);

    if (resultWedding.success) {
      await this.mngReserves(resultWedding.data);
    }
  }

  mngReserves = async (wedding) => {
    const { history, translate } = this.props;
    const { reserveList, removeReserveList } = this.state;

    const data = {
      wedding,
      reserves: reserveList,
      removeReserves: removeReserveList
    };
    const resultReserves = await MngMultipleReserves(data);
    // console.warn('resultReserves', resultReserves);

    if (resultReserves.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('RESERVES_SAVED')
      });

      this.setState({
        reserveList: [],
        removeReserveList: []
      }, () => history.push('/reserves'), 200);
    }
  }

  showModalReserve = async () => {
    const { translate } = this.props;
    const { reserveList, local } = this.state;

    if (reserveList.length === 0) {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_NO_RESERVES')
      });
    }

    this.setState({ showConfirmModal: true });
  }

  openModalReserve = async () => {
    const { reserveList } = this.state;

    const result = await GetReservesWithOrder(reserveList);
    if (result.success) {
      this.setState({
        showConfirmModal: false,
        showModal: true,
        reserveList: result.data
      });
    }
  }

  continueReservations = () => {
    const { local } = this.state;
    this.setState({ showConfirmModal: false }, () => this.getAgenda(false, false));
  }

  changeWeddingWaitingInfo = async (event) => {
    const { translate } = this.props;
    const { untilPeriodNotificationWaitingDate, acceptedWaitingPolicy } = this.state;
    this.setState({ savingWeddingWaiting: true });

    const data = {
      period: untilPeriodNotificationWaitingDate,
      accepted: acceptedWaitingPolicy
    };
    const result = await UpdateWaitingInfo(data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('WAITING_DADES_INFO_UPDATED')
      });
      this.setState({
        wedding: result?.data,
        acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy || false,
        untilPeriodNotificationWaitingDate: result?.data?.untilPeriodNotificationWaitingDate || 2,
        savingWeddingWaiting: false
      });
    } else {
      this.setState({ savingWeddingWaiting: false });
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('GENERIC_ERROR')
      });
    }

  }

  changeWeddingInterestInfo = async (event) => {
    const { translate } = this.props;
    const { acceptedInterestPolicy } = this.state;
    this.setState({ savingWeddingInterest: true });

    const data = {
      accepted: event
    };
    const result = await UpdateInterestInfo(data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('INTEREST_DADES_INFO_UPDATED')
      });
      this.setState({
        wedding: result?.data,
        acceptedInterestPolicy: result?.data?.acceptedInterestPolicy || false,
        showInterestPolicyModal: false,
        savingWeddingInterest: false
      });
      window.location.reload(true);
    } else {
      this.setState({ savingWeddingInterest: true });
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('GENERIC_ERROR')
      });
    }
  }

  closeInterestPolicyModal = () => {
    const { history } = this.props;
    return history.push('/home');
    // this.setState({ showInterestPolicyModal: false });
  }

  render() {
    const { translate, history, activeLanguage, location, menuOpen } = this.props;
    const { content, loading, databaseActions, wedding } = this.state;
    const { untilPeriodNotificationWaitingDate, acceptedWaitingPolicy, savingWeddingWaiting } = this.state;
    const { savingWeddingInterest, acceptedInterestPolicy } = this.state;
    const { loadingAgenda, showAgenda, canActionOnReserves, agendaType } = this.state;
    const { year, yearFilter, local, dataSource, waitingDates, interestDates } = this.state;
    const { reserveList, reservesLoading, editReserve, editReserveDate } = this.state;
    const { showModal, showWaitingPolicyModal, showInterestPolicyModal } = this.state;
    const { showDateModal, dataDateModal } = this.state;
    const { showConfirmModal } = this.state;
    const { selectingDay, selectingWaitingDate, waitingDatesLoading, selectingInterestDate, interestDatesLoading } = this.state;

    const reservePhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) ? true : false;
    const reserveSinalizationPhase = wedding && wedding?.status && wedding?.status === WeddingStatus.RESERVE_SINALIZATION ? true : false;
    const returnToVisits = new URLSearchParams(location.search).get('return') === 'V' ? true : false;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <XpertGoLeavePageBlocker
          when={databaseActions > 0}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <Content>
          <Container confirmed={!reservePhase && !reserveSinalizationPhase}>
            {showAgenda ?
              <Row gutter={[12, 12]} type='flex'>
                <Col
                  xl={{ span: reservePhase ? 20 : 18, order: 1 }}
                  lg={{ span: reservePhase ? 19 : 17, order: 1 }}
                  md={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  xs={{ span: 24, order: 2 }}>
                  <CalendarBox reserves={true}>
                    <Title>
                      {agendaType === AgendaType.WAITING_DATE
                        ? translate('MNG_WAITING_DATE_LIST')
                        : agendaType === AgendaType.INTEREST
                          ? translate('MNG_INTEREST_LIST')
                          : translate('RESERVE_YOUR_DAY')}
                    </Title>

                    <Row gutter={[12, 12]}>
                      <Col xs={24} md={12} lg={11} xl={!reservePhase && menuOpen ? 11 : !reservePhase ? 9 : 8} xxl={8}>
                        <RadioPlace>
                          <Radio.Group value={local} disabled={loadingAgenda} onChange={(event) => this.changeTab(event)}>
                            {locals.map((local, index) => (
                              <Radio.Button key={index} value={local._id}>
                                {local.name}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </RadioPlace>
                      </Col>
                      <Col xs={24} md={7} lg={9} xl={!reservePhase && menuOpen ? 7 : !reservePhase ? 11 : 10} xxl={!reservePhase && menuOpen ? 10 : 10}>
                        <RadioYear menuOpen={menuOpen} reservePhase={reservePhase}>
                          <Radio.Group value={0} disabled={loadingAgenda} onChange={(event) => this.changeYear(event)}>
                            <Radio.Button value={-1} disabled={year <= moment.utc().year()}>
                              <Tooltip className='iconPrevious' title={translate('PREVIOUS_YEAR')}>
                                <Icon type="left" />
                              </Tooltip>

                              <span className='spanPrevious'>
                                <Icon type="left" />
                                {translate('PREVIOUS_YEAR')}
                              </span>
                            </Radio.Button>
                            <Radio.Button value={0}>{year}</Radio.Button>
                            <Radio.Button value={1}>
                              <Tooltip className='iconNext' title={translate('NEXT_YEAR')}>
                                <Icon type="right" />
                              </Tooltip>

                              <span className='spanNext'>
                                {translate('NEXT_YEAR')}
                                <Icon type="right" />
                              </span>
                            </Radio.Button>
                          </Radio.Group>
                        </RadioYear>
                      </Col>
                      <Col xs={24} md={5} lg={4} xl={!reservePhase && menuOpen ? 6 : !reservePhase ? 4 : 6} xxl={!reservePhase && menuOpen ? 6 : 6}>
                        <CalendarPicker menuOpen={menuOpen} reservePhase={reservePhase}>
                          <XpertGoYearInput
                            placeholder={translate('SELECT_YEAR')}
                            input={{
                              value: yearFilter,
                              onChange: (value) => {
                                if (value && parseInt(value) < moment.utc().year()) {
                                  return Alerts.new({
                                    type: 'warning',
                                    title: translate('ATTENTION'),
                                    text: translate('ERROR_YEAR_BEFORE_TODAY')
                                  });
                                }
                                this.setState({
                                  yearFilter: value,
                                  year: value !== '' ? parseInt(value) : moment.utc().year()
                                }, this.getAgenda);
                              }
                            }}
                            disabled={loadingAgenda}
                            allowClear={true}
                            meta={{}} />
                        </CalendarPicker>
                      </Col>
                    </Row>

                    <Row>
                      <div style={{ marginTop: '2rem', position: 'relative' }}>
                        {loadingAgenda ?
                          <Loading src={LoadingIcon} /> :
                          <React.Fragment>
                            {(reservesLoading || selectingWaitingDate || waitingDatesLoading || selectingInterestDate || interestDatesLoading)
                              && <div className='backdrop'>
                                <Loading src={LoadingIcon} style={{ position: 'absolute', minHeight: 30, height: 30, top: '40%', right: '45%' }} />
                              </div>}
                            <Calendar
                              loading={loadingAgenda || reservesLoading || selectingDay || selectingWaitingDate || waitingDatesLoading || selectingInterestDate || interestDatesLoading}
                              localizer={localizer}
                              activeLanguage={activeLanguage}
                              translate={translate}
                              selecting={selectingDay || selectingWaitingDate || selectingInterestDate}
                              defaultDate={moment.utc().set('year', year)}
                              events={dataSource}
                              waitingDates={waitingDates}
                              interestDates={interestDates}
                              wedding={wedding}
                              reserveView={agendaType === AgendaType.AGENDA}
                              agendaType={agendaType}
                              menuOpen={menuOpen}
                              toolbar={false}
                              defaultView='year'
                              views={{
                                // day: true,
                                // week: true,
                                // month: true,
                                year: YearView
                              }}
                              messages={{ year: 'Year' }}
                              onDayClick={this.onDayClick}
                              onWaitingClick={this.onWaitingClick}
                              onMultipleWaitingClick={this.onMultipleWaitingClick}
                              onInterestClick={this.onInterestClick}
                            />
                            <Legend>
                              <LegendItem><b>{translate('HOLIDAY_SHORT')}:</b> {translate('HOLIDAY_LEGEND')}</LegendItem>
                              <LegendItem><b>{translate('LOCAL_HOLIDAY_SHORT')}:</b> {translate('LOCAL_HOLIDAY_LEGEND')}</LegendItem>
                              <LegendItem><b>{translate('UNAVAILABLE_DATE_SHORT')}:</b> {translate('UNAVAILABLE_DATE')}</LegendItem>
                              {wedding !== null && wedding !== undefined && agendaType === AgendaType.WAITING_DATE
                                && <LegendItem><b><WaitingIcon src={Bookmark} />:</b> {translate('WAITING_DATE_LIST')}</LegendItem>}
                              {wedding !== null && wedding !== undefined && agendaType === AgendaType.INTEREST
                                && <LegendItem><b><WaitingIcon src={Star} />:</b> {translate('INTEREST_DATE_LIST')}</LegendItem>}
                            </Legend>
                          </React.Fragment>
                        }
                      </div>
                    </Row>
                  </CalendarBox>
                </Col>
                <Col className='reserve-column'
                  xl={{ span: 4, order: 2 }}
                  lg={{ span: 5, order: 2 }}
                  md={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  xs={{ span: 24, order: 1 }}>
                  <ReserveContent>
                    {wedding && (reservePhase || reserveSinalizationPhase) && <React.Fragment>
                      <Row gutter={[12, 12]} className='reserve-title'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <ReserveTitle>{translate('SELECT_AGENDA')}</ReserveTitle>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]} className='reserve-title'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Radio.Group
                            onChange={(e) => this.setState({ agendaType: e?.target.value }, () => this.props.history.push('/' + e?.target.value))}
                            value={agendaType}
                            disabled={loadingAgenda}
                            className="agenda-options">
                            <Radio value={AgendaType.AGENDA}>{translate('RESERVE_AGENDA')}</Radio>
                            {inAgendaTest() && wedding?.visitsBlocked && wedding?.visitsBlocked == VisitsBlockedType.NONE && <Radio value={AgendaType.VISIT}>{translate('RESERVE_AGENDA_PLUS_VISITS')}</Radio>}
                            {inAgendaTest() && reservePhase && <Radio value={AgendaType.INTEREST}>{translate('RESERVE_AGENDA_PLUS_INTEREST_DATES')}</Radio>}
                            <Radio value={AgendaType.WAITING_DATE}>{translate('RESERVE_AGENDA_PLUS_WAITING_DATE_LIST')}</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    </React.Fragment>}

                    {agendaType === AgendaType.AGENDA && <React.Fragment>
                      <Row gutter={[12, 12]} className='reserve-title'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <ReserveTitle>{translate('RESERVES_LIST')}</ReserveTitle>
                        </Col>
                      </Row>

                      <Row gutter={[12, 12]}>
                        {locals.map((local, index) => (
                          <Col key={index} xs={24} sm={12} md={12} lg={24} xl={24}>
                            <ReserveSubtitle>{local.name}</ReserveSubtitle>
                            {reservesLoading && <Loading src={LoadingIcon} width={30} />}
                            {reserveList.filter(f => f.weddingPlace === local._id).length === 0 && <p>{translate('NO_RESERVES')}</p>}
                            {reserveList.filter(f => f.weddingPlace === local._id).length > 0 &&
                              <ReserveList>
                                {reserveList.filter(f => f.weddingPlace === local._id)
                                  .map(m => ({ ...m, date: m?.date && moment.utc(m.date).isValid() ? moment.utc(m.date).toISOString() : m.date }))
                                  .sort((a, b) => a.date && b.date && a.date > b.date ? 1 : -1)
                                  .map((reserve, indexR) => (
                                    <ReserveItem key={indexR} edit={editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && reserve?.weddingPlace === editReserve?.weddingPlace && reserve?.date && editReserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day')}>
                                      <div>
                                        <p style={{ marginBottom: 0 }}>
                                          <b>{translate('DATE')}: </b>
                                          {moment.utc(reserve.date).format('DD/MM/YYYY')}
                                        </p>
                                      </div>
                                      {/* Options on reserveList will only appear if wedding.reservesBlocked is false */}
                                      {!wedding?.reservesBlocked && canActionOnReserves && <div>
                                        {(reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === undefined ||
                                          (reserve?.event?.resource?.weddingReserve?.sentWaitingNotification !== undefined &&
                                            reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === false)) && <React.Fragment>
                                            {(reserve && reserve?.event?.resource?.weddingReserve
                                              && reserve?.event?.resource?.weddingReserve?.status === ReserveStatus.RSC
                                              && editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve?.weddingPlace
                                              && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day'))
                                              || (editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve?.weddingPlace
                                                && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day'))
                                              ? <Tooltip title={translate('CANCEL_EDIT')}>
                                                <Icon type='close-circle' onClick={() => this.cancelEditDate(reserve)}></Icon>
                                              </Tooltip>
                                              : <Tooltip title={translate('EDIT')}>
                                                <Icon type='edit' onClick={() => this.editDate(reserve)}></Icon>
                                              </Tooltip>
                                            }
                                          </React.Fragment>}
                                        {canActionOnReserves && reserve && reserve.event && reserve.event.resource
                                          && ((reserve.event.resource.weddingReserve && reserve.event.resource.weddingReserve.status === ReserveStatus.RSC)
                                            || !reserve?.event?.resource?.weddingReserve)
                                          && <Popconfirm
                                            placement="topRight"
                                            title={this.getRemoveMessage()}
                                            okText={translate('DELETE')}
                                            cancelText={translate('CANCEL')}
                                            onConfirm={async () => await this.removeDate(reserve)}>
                                            <Icon type="delete" />
                                          </Popconfirm>
                                        }
                                      </div>}
                                    </ReserveItem>
                                  ))
                                }
                              </ReserveList>
                            }
                          </Col>
                        ))}
                      </Row>

                      <Row gutter={[12, 12]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        {!wedding?.reservesBlocked && canActionOnReserves && <SaveButton
                          htmlType="submit"
                          // error={submitFailed && invalid}
                          text={translate("END_RESERVE")}
                          textMobile={translate("RESERVE")}
                          onClick={this.showModalReserve}
                          loading={loading || loadingAgenda ? true : false}
                        />}
                      </Row>
                    </React.Fragment>}

                    {agendaType === AgendaType.WAITING_DATE && <React.Fragment>
                      <Row gutter={[12, 12]} className='reserve-title'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <ReserveTitle>{translate('WAITING_DATE_LIST')}</ReserveTitle>
                        </Col>
                      </Row>

                      <Row gutter={[12, 12]}>
                        {(loading || loadingAgenda)
                          ? <Loading src={LoadingIcon} width={30} />
                          : <React.Fragment>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <ReserveSubtitle>{translate('NOTIFY_WAITING_DATE_UNTIL')}</ReserveSubtitle>
                              <Radio.Group
                                onChange={(e) => this.setState({ untilPeriodNotificationWaitingDate: e?.target?.value || 2 })}
                                value={untilPeriodNotificationWaitingDate}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Radio value={12}>{translate('NOTIFY_WAITING_DATE_UNTIL_12')}</Radio>
                                <Radio value={6}>{translate('NOTIFY_WAITING_DATE_UNTIL_6')}</Radio>
                                <Radio value={2}>{translate('NOTIFY_WAITING_DATE_UNTIL_2')}</Radio>
                              </Radio.Group>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Checkbox
                                checked={acceptedWaitingPolicy}
                                disabled={wedding?.acceptedWaitingPolicy}
                                onChange={(e) => this.setState({ acceptedWaitingPolicy: e?.target?.checked })}>
                                <LinkAcceptPolicy onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ showWaitingPolicyModal: true })
                                }}>{translate('ACCEPT_WAITING_POLICY')}</LinkAcceptPolicy>
                              </Checkbox>
                            </Col>
                          </React.Fragment>}
                      </Row>
                      <Row gutter={[12, 12]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <SaveButton
                          htmlType="submit"
                          icon='save'
                          text={translate("SAVE")}
                          textMobile={translate("SAVE")}
                          onClick={this.changeWeddingWaitingInfo}
                          loading={loading || loadingAgenda || savingWeddingWaiting ? true : false}
                        />
                      </Row>
                    </React.Fragment>}

                    {inAgendaTest() && agendaType === AgendaType.INTEREST && <React.Fragment>
                      <Row gutter={[12, 12]} className='reserve-title'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <ReserveTitle>{translate('INTEREST_DATE_LIST')}</ReserveTitle>
                        </Col>
                      </Row>

                      <Row gutter={[12, 12]}>
                        {(loading || loadingAgenda)
                          ? <Loading src={LoadingIcon} width={30} />
                          : <React.Fragment>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Checkbox
                                checked={acceptedInterestPolicy}
                                disabled={wedding?.acceptedInterestPolicy}
                                onChange={(e) => this.setState({ acceptedInterestPolicy: e?.target?.checked })}>
                                <LinkAcceptPolicy onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ showInterestPolicyModal: true })
                                }}>{translate('ACCEPT_INTEREST_POLICY')}</LinkAcceptPolicy>
                              </Checkbox>
                            </Col>
                          </React.Fragment>}
                      </Row>
                      <Row gutter={[12, 12]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <SaveButton
                          htmlType="submit"
                          text={returnToVisits ? translate('RETURN_VISITS') : translate("VISITS")}
                          textMobile={translate("VISITS")}
                          onClick={() => this.props.history.push('/visit-agenda')}
                          loading={loading || loadingAgenda || savingWeddingWaiting ? true : false}
                        />
                      </Row>
                    </React.Fragment>}
                  </ReserveContent>
                </Col>
              </Row>
              :
              <React.Fragment>
                <RowButtons>
                  <StyledButton
                    style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}
                    type="primary"
                    bgcolor="primaryColor"
                    onClick={this.presentAgenda}
                    align={'right'}
                  >
                    <span>{translate('RESERVES')}</span>
                    <IconAgenda src={ReservesIcon} />
                  </StyledButton>
                  {inAgendaTest() && wedding?.visitsBlocked && wedding?.visitsBlocked == VisitsBlockedType.NONE && <StyledButton
                    style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginLeft: 16 }}
                    type="primary"
                    bgcolor="primaryColor"
                    onClick={() => this.props.history.push('/visit-agenda')}
                    align={'right'}
                  >
                    <span>{translate('VISITS')}</span>
                    <IconAgenda src={VisitsIcon} />
                  </StyledButton>}
                  {inAgendaTest() && reservePhase && <StyledButton
                    style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginLeft: 16 }}
                    type="primary"
                    bgcolor="primaryColor"
                    onClick={() => this.props.history.push('/interest-agenda')}
                    align={'right'}
                  >
                    <span>{translate('INTEREST_DATE')}</span>
                    <IconAgenda src={InterestListIcon} />
                  </StyledButton>}
                  <StyledButton
                    style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginLeft: 16 }}
                    type="primary"
                    bgcolor="primaryColor"
                    onClick={() => this.props.history.push('/waiting-agenda')}
                    align={'right'}
                  >
                    <span>{translate('WAITING_DATE')}</span>
                    <IconAgenda src={WaitingListIcon} />
                  </StyledButton>
                </RowButtons>
                < DraftJSContent content={content?.description} />
                {/* <BaseButton type="primary" text={translate('GO_TO_AGENDA')} onClick={this.presentAgenda} /> */}

                <UpButton icon='top' />
              </React.Fragment>
            }

            {showModal && <ReserveInfoModal
              isOpen={showModal}
              loading={loading || loadingAgenda}
              onClose={() => this.setState({ showModal: false })}
              onSubmit={this.finishReserve}
              initialValues={wedding}
              reserves={reserveList}
              deleteDate={($event) => this.removeDate($event)}
              editDate={($event) => this.setState({ showModal: false }, () => this.editDate($event))}
            />}

            {showDateModal && <DateWarningModal
              openModal={showDateModal}
              closeModal={() => this.setState({ showDateModal: false, dataDateModal: null })}
              onSubmit={(value) => this.editReserveDate(value)}
              // loading={loadingButton}
              initialValues={dataDateModal}
              translate={translate}
              activeLanguage={activeLanguage}
            />}

            {showWaitingPolicyModal
              && <WaitingDatesPolicyModal
                openModal={showWaitingPolicyModal}
                closeModal={() => this.setState({ showWaitingPolicyModal: false })}
                agendaType={agendaType}
                translate={translate}
                activeLanguage={activeLanguage}
              />}

            {showInterestPolicyModal
              && <InterestPolicyModal
                openModal={showInterestPolicyModal}
                closeModal={() => this.closeInterestPolicyModal()}
                saveModal={(event) => this.changeWeddingInterestInfo(event)}
                agendaType={agendaType}
                initialValues={wedding}
                translate={translate}
                activeLanguage={activeLanguage}
              />}

            {showConfirmModal && <Modal
              title={translate("ATTENTION")}
              visible={showConfirmModal}
              onOk={this.openModalReserve}
              onCancel={this.continueReservations}
              okText={translate('END_RESERVE')}
              cancelText={translate('CONTINUE_TO_RESERVE')}
            >
              <p>{translate("CONFIRM_CONTINUE_TO_RESERVE")}</p>
            </Modal>}
          </Container>
        </Content>
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  menuOpen: state.menu
});

export default withLocalize(withRouter(connect(mapStateToProps)(AgendaPage)));