//Check if a option still exists in the question
export const checkOption = (value, question, weddingPlace) => {
  let res = false;

  let id = value;
  if(value.id) {
    id = value.id;
  }

  if(
    question && 
    (question.type === 4 || question.type === 5 || question.type === 6) && 
    question.options && 
    question.options.length > 0 && 
    question.options.find(x => x._id === id && (!x.local || x.local === weddingPlace))) {
    res = true;
  }
  else if(question && question.type === 10 && (id === 'true' || id === 'false')) {
    res = true;
  }
  
  return res;
}

//Check if a food option still exists in the question
//We pass directly the Food group (meat, fish, etc...)
export const checkFoodOption = (value, food) => {
  let res = false;

  let id = value;
  if(value.id) {
    id = value.id;
  }

  if(food && food.length > 0 && food.find(x => x._id === id)) {
    res = true;
  }
  
  return res;
}