import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SaveWedding } from "../../redux/Wedding/wedding.actions";
import { ReservesBlock } from "../../infra/requests/MyWeddingRequests";
import {
  Title,
  Subtitle,
  ReserveContainer,
  TableHeader,
} from "./ReserveStyles";
import moment from "moment";
import { SpinLoading, TableButton } from "../../styles/BasicStyles";
import {
  CheckReservesReactivations,
  ConfirmReserve,
  GetReserve,
  GetReserves,
  GetReservesByPlace,
  ReactivateReserve,
  SoftDeleteReserve,
} from "../../infra/requests/ReserveRequests";
import { locals } from "../agenda/AgendaPage";
import { DateColumn, TableContent } from "../agenda/ReserveInfoModal";
import Table from "../../components/table/Table";
import OrdinalNumberComponent from "../../components/ordinalNumber/OrdinalNumberComponent";
import { Content } from "../agenda/AgendaStyles";
import { Icon, Popconfirm, Tooltip } from "antd";
import Alerts from "../../components/alert/Alert";
import CurrencyComponent from "../../components/currency/CurrencyComponent";
import DateInput from "../../components/inputs/DateInput";
import ReserveActionEmailModal from "./ReserveActionEmailModal";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import momentTimezone from "moment-timezone";

export const ReserveStatus = {
  OCCUPIED: "O", // O: Date Occupied
  SINALIZATION: "S", // S: Date in process of sinalization
  RSC: "RSC", // RSC: Reserve without compromisse
  INACTIVE: "I", // I: Inactive
  ARCHIVED: "A", // A: Archived
  // DELETED: 'D', // D: Deleted
};

export const WeddingStatus = {
  ARCHIVED: "A",
  CONFIRMED: "C",
  RESERVE: "R",
  RESERVE_SINALIZATION: "RS",
};

const TableActions = {
  EDIT: 1,
  REACTIVATE: 2,
  CONFIRM: 3,
  DELETE: 4,
};

class ReservePage extends Component {
  state = {
    loading: false,
    wedding: {},
    loadingSolar: false,
    loadingLago: false,
    columns: [
      {
        title: this.props.translate("WAITING_LIST"),
        titleKey: "WAITING_LIST",
        width: "80px",
        render: (data) => {
          const { activeLanguage } = this.props;
          return data.status === ReserveStatus.INACTIVE ? (
            ""
          ) : (
            <OrdinalNumberComponent
              value={data.order}
              activeLanguage={activeLanguage}
            />
          );
        },
      },
      {
        title: this.props.translate("DATE"),
        titleKey: "DATE",
        width: "85px",
        render: (data) => {
          const { activeLanguage } = this.props;
          return data?.date ? (
            <DateColumn>
              <span className="date">
                {moment.utc(data.date).format("DD-MM-YYYY")}
              </span>
              <span className="weekdayName">
                {moment
                  .utc(data.date)
                  .locale(activeLanguage.code)
                  .format("dddd")}
              </span>
            </DateColumn>
          ) : (
            ""
          );
        },
      },
      {
        title: this.props.translate("PAX"),
        titleKey: "PAX",
        render: (data) => {
          const now = moment.utc();
          const reserveIsActive = now.isSameOrBefore(
            moment.utc(data.endActivationDate)
          );
          return reserveIsActive ? data.priceObj.minPax : data.price.minPax;
        },
      },
      {
        title: this.props.translate("PRICE"),
        titleKey: "PRICE",
        render: (data) => {
          const now = moment.utc();
          const reserveIsActive = now.isSameOrBefore(
            moment.utc(data.endActivationDate)
          );
          const price = reserveIsActive
            ? data.priceObj.minPrice
            : data.price.minPrice;
          return <CurrencyComponent value={price} />;
        },
      },
      {
        title: this.props.translate("MINIMUM_GLOBAL_PRICE"),
        titleKey: "MINIMUM_GLOBAL_PRICE",
        render: (data) => {
          const now = moment.utc();
          const reserveIsActive = now.isSameOrBefore(
            moment.utc(data.endActivationDate)
          );
          const price = reserveIsActive
            ? data.priceObj?.minPrice
            : data.price?.minPrice;
          const pax = reserveIsActive
            ? data.priceObj?.minPax
            : data.price?.minPax;
          return <CurrencyComponent value={Number((price * pax).toFixed(2))} />;
        },
      },
      {
        title: this.props.translate("RESERVE_VALIDITY"),
        titleKey: "RESERVE_VALIDITY",
        render: (data) => {
          const validityDecision = moment
            .utc(data.waitingNotificationDate)
            .add(72, "hours");
          const validityActivation = moment.utc(data.endActivationDate);
          const validity =
            data?.sentWaitingNotification &&
              validityDecision.isSameOrBefore(validityActivation)
              ? validityDecision
              : validityActivation;
          return (
            momentTimezone(validity)
              .tz("Europe/Lisbon")
              .format("DD-MM-YYYY HH:mm") + " - " + this.props.translate('LISBON_TIME')
          );
        },
      },
      {
        title: this.props.translate("ACTIONS"),
        titleKey: "ACTIONS",
        titleBlocked: "STATUS",
        render: (data) => {
          const { translate } = this.props;
          const { wedding } = this.state;

          // Check if there are 1 day or less left to end the reservation activation period
          const now = moment.utc();
          const canAction = now.isSameOrBefore(moment.utc(data.date), "day");
          const isActive = now.isSameOrBefore(
            moment.utc(data.endActivationDate)
          );
          const canReactive =
            moment.utc(data.endActivationDate).diff(now, "hours") <= 48
              ? true
              : false;
          const status =
            data?.status && data?.status === ReserveStatus.SINALIZATION
              ? translate("IN_SIGNAGE")
              : data?.status === ReserveStatus.OCCUPIED
                ? translate("CONFIRMED")
                : "";

          return wedding && wedding?.reservesBlocked ? (
            status
          ) : (
            <React.Fragment>
              {data.status === ReserveStatus.RSC && (data.sentWaitingNotification === undefined || (data.sentWaitingNotification !== undefined && data.sentWaitingNotification === false)) && (
                <TableButton
                  onClick={async () =>
                    await this.reserveActions(TableActions.EDIT, data)
                  }
                >
                  <Icon type="edit" />
                  {translate("EDIT_RESERVE")}
                </TableButton>
              )}
              {data?.status &&
                !data?.sentWaitingNotification &&
                (data.status === ReserveStatus.INACTIVE ||
                  (canReactive && data?.status === ReserveStatus.RSC)) &&
                canAction && (
                  <TableButton onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={this.getReactivateMessage()}
                      okText={translate("REACTIVATE")}
                      cancelText={translate('CANCEL')}
                      // onConfirm={async () => await this.reserveActions(TableActions.REACTIVATE, data)}>
                      onConfirm={async () => await this.reactivateReserve(data)}
                    >
                      <Icon type="check-circle" />
                      {translate("REACTIVATE_RESERVE")}
                    </Popconfirm>
                  </TableButton>
                )}
              {data?.status &&
                data.status === ReserveStatus.RSC &&
                data?.order &&
                data?.order === 1 &&
                isActive &&
                canAction && (
                  <TableButton onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={this.getConfirmMessage()}
                      okText={translate("CONFIRM")}
                      cancelText={translate('CANCEL')}
                      // onConfirm={async () => await this.reserveActions(TableActions.CONFIRM, data)}>
                      onConfirm={async () => await this.confirmReserve(data)}
                    >
                      <Icon type="carry-out" />
                      {translate("CONFIRM_RESERVE")}
                    </Popconfirm>
                  </TableButton>
                )}
              {(data.status === ReserveStatus.RSC ||
                data.status === ReserveStatus.INACTIVE) && (
                  <TableButton onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                      placement="topRight"
                      title={this.getRemoveMessage()}
                      okText={translate("DELETE")}
                      cancelText={translate('CANCEL')}
                      // onConfirm={async () => await this.reserveActions(TableActions.DELETE, data)}>
                      onConfirm={async () => await this.deleteReserve(data)}
                    >
                      <Icon type="delete" />
                      {translate("DELETE_RESERVE")}
                    </Popconfirm>
                  </TableButton>
                )}
            </React.Fragment>
          );
        },
      },
    ],
    rowsSolar: [],
    filtersSolar: { weddingPlace: 1 },
    dateSolar: null,
    rowsLago: [],
    filtersLago: { weddingPlace: 2 },
    dateLago: null,

    showActionEmailModal: false,
    dataActionEmail: null,
    loadingActionEmail: false,
  };

  componentDidMount = () => {
    const { location, wedding } = this.props;

    document.body.style.overflowY = "auto";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const queryEmail =
      new URLSearchParams(location.search).get("email") !== null ? true : false;
    const queryAction = new URLSearchParams(location.search).get("action");
    const queryReserveId = new URLSearchParams(location.search).get("reserve");
    if (queryEmail) {
      this.getReserveInfo(queryReserveId, queryAction);
    }

    this.setState({ wedding }, () => this.loadComponent());
  };

  componentDidUpdate(prevProps) {
    const { translate, activeLanguage } = this.props;
    let { columns } = this.state;

    // If activeLanguage changes, then update column titles in table
    if (prevProps.activeLanguage.code !== activeLanguage.code) {
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        if (column.titleKey) column.title = translate(column.titleKey);
      }
      this.setState({ columns });
    }
  }

  loadComponent = async () => {
    const { translate } = this.props;
    const { wedding } = this.state;
    let { columns } = this.state;

    // If wedding.reservesBlocked === true, then update action column to status column
    if (wedding && wedding.reservesBlocked) {
      if (columns[columns.length - 1].titleBlocked)
        columns[columns.length - 1].title = translate(
          columns[columns.length - 1].titleBlocked
        );
      this.setState({ columns });
    }

    await this.updateDatatableSolar();
    await this.updateDatatableLago();
    this.setState({ loading: false });
  };

  updateDatatableSolar = async () => {
    try {
      this.setState({ loadingSolar: true });
      const { filtersSolar, dateSolar } = this.state;

      if (dateSolar !== null) filtersSolar["date"] = dateSolar;
      else delete filtersSolar.date;

      const result = await GetReserves(JSON.stringify(filtersSolar));
      // console.warn(`result solar`, result);

      const rows = result.success && result.data ? result.data : [];

      this.setState({
        loadingSolar: false,
        rowsSolar: rows,
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingSolar: false });
    }
  };

  updateDatatableLago = async () => {
    try {
      this.setState({ loadingLago: true });
      const { filtersLago, dateLago } = this.state;

      if (dateLago !== null) filtersLago["date"] = dateLago;
      else delete filtersLago.date;

      const result = await GetReserves(JSON.stringify(filtersLago));
      // console.warn('result lago', result);

      const rows = result.success && result.data ? result.data : [];

      this.setState({
        loadingLago: false,
        rowsLago: rows,
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingLago: false });
    }
  };

  getReserveInfo = async (reserveId, action) => {
    try {
      const result = await GetReserve(reserveId);
      // console.warn('result', result);
      const reserve = result?.data ? result?.data : null;

      if (result?.success && reserve && !reserve?.deleted) {
        this.setState({
          showActionEmailModal: true,
          dataActionEmail: {
            action,
            reserve: result.data,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /** Messages for PopConfirms on Table */
  getRemoveMessage = () => {
    const { translate } = this.props;

    return (
      <div>
        <div>{translate("DELETE_RESERVE_QUESTION")}</div>
        <div>{translate("DELETE_RESERVE_INFO")}</div>
      </div>
    );
  };

  getReactivateMessage = () => {
    const { translate } = this.props;

    return (
      <div>
        <div>{translate("REACTIVATE_RESERVE_QUESTION")}</div>
        <div>{translate("REACTIVATE_RESERVE_INFO")}</div>
      </div>
    );
  };

  getConfirmMessage = () => {
    const { translate } = this.props;

    return (
      <div>
        <div>{translate("CONFIRM_RESERVE_QUESTION")}</div>
        <div>{translate("CONFIRM_RESERVE_INFO")}</div>
      </div>
    );
  };
  /** end Messages for PopConfirms on Table */

  reserveActions = async (action, reserve) => {
    const { translate } = this.props;

    // Check if any actions can still be made to this wedding reserves
    const resultCheck = await CheckReservesActions();
    // console.warn('resultCheck', resultCheck);

    if (resultCheck.success) {
      if (action === TableActions.EDIT) {
        await this.goToAgendaEdit(reserve);
      } else if (action === TableActions.CONFIRM) {
        await this.confirmReserve(reserve);
      } else if (action === TableActions.REACTIVATE) {
        await this.reactivateReserve(reserve);
      } else if (action === TableActions.DELETE) {
        await this.deleteReserve(reserve);
      }
    } else {
      Alerts.new({
        type: "error",
        title: translate("ERROR"),
        text: translate(resultCheck.data),
      });
      // await this.reservesBlockWedding();
    }
  };

  goToAgendaEdit = async (reserve) => {
    const { history } = this.props;
    history.push(`/agenda?reserve=${reserve?._id}`);
  };

  reactivateReserve = async (reserve, fromEmail = false) => {
    const { translate } = this.props;
    if (fromEmail) this.setState({ loadingActionEmail: true });

    const canReactivate = await CheckReservesReactivations();
    if (canReactivate?.success) {
      const result = await ReactivateReserve(reserve._id);

      if (result.success) {
        Alerts.new({
          type: "success",
          title: translate("SUCCESS"),
          text: translate("RESERVE_REACTIVATED"),
        });
        reserve.weddingPlace === 2
          ? this.updateDatatableLago()
          : this.updateDatatableSolar();
      } else {
        if (
          result?.data === "ERROR_DATE_OCCUPIED" ||
          result?.data === "ERROR_CANT_REACTIVATE" ||
          result?.data === "ERROR_REACTIVATE_RESERVES" ||
          result?.data === "ERROR_DATE_UNAVAILABLE" ||
          result?.data === "NOT_AUTHORIZED" ||
          result?.data === "ERROR_TAKE_DECISION"
        ) {
          Alerts.new({
            type: "error",
            title: translate("ERROR"),
            text: translate(result?.data),
          });
        } else if (result?.data === "ERROR_RESERVE_ACTIVE" || result?.data === 'ERROR_RESERVE_ACTIVE_RELOAD') {
          Alerts.new({
            type: "warning",
            title: translate("ATTENTION"),
            text: translate('ERROR_RESERVE_ACTIVE'),
          });
          if (result?.data === 'ERROR_RESERVE_ACTIVE_RELOAD') {
            this.loadComponent();
          }
        } else {
          Alerts.new({
            type: "error",
            title: translate("ERROR"),
            text: result?.data,
          });
        }
      }
    } else {
      if (canReactivate?.data === "LIMIT_REACTIVATION_RESERVES") {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: translate(canReactivate?.data),
        });
      } else {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: canReactivate?.data,
        });
      }
    }

    if (fromEmail) this.cancelActionEmail();
  };

  confirmReserve = async (reserve, fromEmail = false) => {
    const { translate, dispatch } = this.props;
    const { SaveWedding, history } = this.props;

    if (fromEmail) this.setState({ loadingActionEmail: true });
    const result = await ConfirmReserve(reserve._id);

    if (result.success) {
      this.setState({ wedding: result?.data?.wedding }, () =>
        this.loadComponent()
      );
      history.push(['confirmations']);
    } else {
      if (
        result?.data === "ERROR_DATE_OCCUPIED" ||
        result?.data === "ERROR_CANT_CONFIRM" ||
        result?.data === "ERROR_DATE_UNAVAILABLE" ||
        result?.data === "NOT_AUTHORIZED"
      ) {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: translate(result?.data),
        });
      } else {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: result?.data,
        });
      }
    }

    if (fromEmail) this.cancelActionEmail();
  };

  deleteReserve = async (reserve, fromEmail = false) => {
    const { translate } = this.props;

    if (fromEmail) this.setState({ loadingActionEmail: true });
    const result = await SoftDeleteReserve(reserve._id, true);
    // console.warn('result', result);

    if (result?.success) {
      reserve.weddingPlace === 2
        ? this.updateDatatableLago()
        : this.updateDatatableSolar();
    } else {
      if (
        result?.data === "ERROR_DATE_OCCUPIED" ||
        result?.data === "NOT_AUTHORIZED"
      ) {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: translate(result.data),
        });
      } else {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: result?.data,
        });
      }
    }

    if (fromEmail) this.cancelActionEmail();
  };

  submitActionEmail = async (value) => {
    const { translate } = this.props;

    value.reserve.sentWaitingNotification = false;
    value.reserve.waitingNotificationDate = null;

    // Check if any actions can still be made to this wedding reserves
    const resultCheck = await CheckReservesActions();

    if (resultCheck.success) {
      if (value.action === "confirm") {
        await this.confirmReserve(value.reserve, true);
      } else if (value.action === "reactivate") {
        await this.reactivateReserve(value.reserve, true);
      } else if (
        value.action === "cancelreactivate" ||
        value.action === "remove"
      ) {
        await this.deleteReserve(value.reserve, true);
      }
    } else {
      Alerts.new({
        type: "error",
        title: translate("ERROR"),
        text: translate(resultCheck.data),
      });
      await this.reservesBlockWedding();
    }
  };

  reservesBlockWedding = async () => {
    const result = await ReservesBlock();

    if (result.success) {
      this.setState({ wedding: result.data }, () => this.loadComponent());
    }
  };

  cancelActionEmail = async () => {
    const { history } = this.props;
    this.setState({
      showActionEmailModal: false,
      dataActionEmail: null,
      loadingActionEmail: false,
    });
    history.push("/reserves");
  };

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { wedding } = this.props;
    const { loading, loadingLago, loadingSolar } = this.state;
    const { columns, rowsSolar, rowsLago } = this.state;
    const { dateSolar, dateLago } = this.state;
    const { showActionEmailModal, dataActionEmail, loadingActionEmail } =
      this.state;

    if (loading) return <SpinLoading />;

    return (
      <Content>
        <ReserveContainer>
          <Title>{translate("OUR_RESERVES")}</Title>

          {locals.map((local, index) => (
            <TableContent key={index} first={index === 0}>
              <TableHeader first={index === 0}>
                <Subtitle>
                  {translate("RESERVES")} {local.name}
                </Subtitle>
                {/* <div className="datepicker">
                  <DateInput
                    input={{
                      value: local._id === 2 ? dateLago : dateSolar,
                      onChange: (value) => {
                        if (local._id === 2) {
                          this.setState({ dateLago: value !== '' ? moment(value, 'DD-MM-YYYY') : null },
                            () => this.updateDatatableLago());
                        } else {
                          this.setState({ dateSolar: value !== '' ? moment(value, 'DD-MM-YYYY') : null },
                            () => this.updateDatatableSolar());
                        }
                      }
                    }}
                    first={true}
                    meta={{ invalid: false, submitFailed: false }} />
                </div> */}
              </TableHeader>

              <Table
                loading={
                  loading || (local?._id === 2 ? loadingLago : loadingSolar)
                }
                columns={columns}
                rows={local._id === 2 ? rowsLago : rowsSolar}
                showHeader={true}
                emptyText={translate("NO_RESERVES_BY_SPACE")}
                rowKey={"id"}
                hasPagination={false}
              />
            </TableContent>
          ))}
        </ReserveContainer>

        <ReserveActionEmailModal
          openModal={showActionEmailModal}
          closeModal={() => this.cancelActionEmail()}
          onSubmit={(value) => this.submitActionEmail(value)}
          loading={loadingActionEmail}
          initialValues={dataActionEmail}
          translate={translate}
          activeLanguage={activeLanguage}
        />
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ SaveWedding }, dispatch);

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(ReservePage))
);
