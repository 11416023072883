import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  GetWedding,
  GetWeddingInfo,
  ReservesBlock,
} from "../../infra/requests/MyWeddingRequests";
import {
  Title,
  Subtitle,
  ReserveContainer,
  TableHeader,
} from "./ReserveStyles";
import moment from "moment";
import { SpinLoading, TableButton } from "../../styles/BasicStyles";
import { ConfirmReserve, GetReserve, GetReserves, GetReservesByPlace, ReactivateReserve, SoftDeleteReserve } from "../../infra/requests/ReserveRequests";
import { locals } from "../agenda/AgendaPage";
import { TableContent } from "../agenda/ReserveInfoModal";
import Table from "../../components/table/Table";
import OrdinalNumberComponent from "../../components/ordinalNumber/OrdinalNumberComponent";
import { Content } from "../agenda/AgendaStyles";
import { Col, Icon, Popconfirm, Row, Tag, Tooltip } from "antd";
import Alerts from "../../components/alert/Alert";
import CurrencyComponent from "../../components/currency/CurrencyComponent";
import DateInput from "../../components/inputs/DateInput";
import ReserveActionEmailModal from "./ReserveActionEmailModal";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import { GetWaitingDates, GetWaitingDatesPaginated, GetWeddingWaitingDates, SoftDeleteWaitingDate } from "../../infra/requests/WaintingDateRequests";
import XpertGoYearInput from "../../components/inputs/XpertGoYearInput";
import XpertGoRangePickerInput from "../../components/inputs/XpertGoRangePickerInput";
import BaseButton from "../../components/buttons/BaseButton";
import { TitleSection } from "../choices/ChoicesStyles";
import { WeddingStatus } from "./ReservePage";
import SaveButton from "../../components/buttons/SaveButton";
import { DeleteInterestDate, GetInterestDatesPaginated } from "../../infra/requests/InterestDateRequests";
import { VisitVenues, VisitVenuesTypes, VisitsBlockedType, dateTimeFormat } from "../agenda/VisitAgendaPage";
import { GetSchedulesPaginated, SoftDeleteSchedule } from "../../infra/requests/ScheduleRequests";
import SelectInput from "../../components/inputs/SelectInput";
import XpertGoSelectInput from "../../components/inputs/XpertGoSelectInput";
import { inAgendaTest } from "../../infra/services/utils/Helpers";
import momentTimezone from "moment-timezone";

class VisitListPage extends Component {
  state = {
    loading: false,
    wedding: {},

    columns: [
      {
        title: this.props.translate('WEEKDAY'),
        titleKey: 'WEEKDAY',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return moment.utc(data.date).locale(activeLanguage.code).format('dddd')
        }
      },
      {
        title: this.props.translate('DATE'),
        titleKey: 'DATE',
        // width: '50px',
        render: data => {
          return moment.utc(data.date).format('DD-MM-YYYY')
        }
      },
      {
        title: this.props.translate('SCHEDULE_TIME'),
        titleKey: 'SCHEDULE_TIME',
        // width: '50px',
        render: data => {
          return <div>
            {momentTimezone.utc(data.startDate).tz("Europe/Lisbon").format('HH:mm')}
            <Icon type="swap-right" />
            {momentTimezone.utc(data.endDate).tz("Europe/Lisbon").format('HH:mm')}
            {' - ' + this.props.translate('LISBON_TIME')}
          </div>
        }
      },
      {
        title: this.props.translate('VENUE_PLACE'),
        titleKey: 'VENUE_PLACE',
        // width: '50px',
        render: data => {
          return data.weddingPlace === 3
            ? this.props.translate(VisitVenuesTypes.BOTH)
            : data.weddingPlace === 2
              ? this.props.translate(VisitVenuesTypes.LAGO)
              : this.props.translate(VisitVenuesTypes.SOLAR);
        }
      },
      {
        title: this.props.translate('ATTENDING_TYPE'),
        titleKey: 'ATTENDING_TYPE',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return (data?.attendingType?.name && data?.attendingType?.name[activeLanguage.code]) || '';
        }
      },
      {
        title: this.props.translate('STATUS'),
        titleKey: 'STATUS',
        // width: '50px',
        render: data => {
          return data?.confirmed
            ? <Tag color="green">{this.props.translate('CONFIRMED')}</Tag>
            : <Tag color="red">{this.props.translate('TO_CONFIRM')}</Tag>;
        }
      },
      {
        title: this.props.translate('ACTIONS'),
        titleKey: 'ACTIONS',
        width: '200px',
        render: data => {
          const { translate } = this.props;
          const date = moment.utc(data.startDate).startOf('day');
          return !data?.presence && moment.utc().isBefore(date, 'day')
            ? <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={this.getDeleteMessage()}
                okText={translate('DELETE')}
                cancelText={translate('CANCEL')}
                onConfirm={async () => await this.deleteSchedule(data)}>
                <Icon type="delete" />
                &nbsp;{translate('REMOVE_VISIT')}
              </Popconfirm>
            </TableButton>
            : null
        }
      }
    ],
    columnsWeddingConfirmed: [
      {
        title: this.props.translate('WEEKDAY'),
        titleKey: 'WEEKDAY',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return moment.utc(data.date).locale(activeLanguage.code).format('dddd')
        }
      },
      {
        title: this.props.translate('DATE'),
        titleKey: 'DATE',
        // width: '50px',
        render: data => {
          return moment.utc(data.date).format('DD-MM-YYYY')
        }
      },
      {
        title: this.props.translate('SCHEDULE_TIME'),
        titleKey: 'SCHEDULE_TIME',
        // width: '50px',
        render: data => {
          return <div>
            {momentTimezone.utc(data.startDate).tz("Europe/Lisbon").format('HH:mm')}
            {' - ' + this.props.translate('LISBON_TIME')}
          </div>
        }
      },
      {
        title: this.props.translate('ATTENDING_TYPE'),
        titleKey: 'ATTENDING_TYPE',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return (data?.attendingType?.name && data?.attendingType?.name[activeLanguage.code]) || '';
        }
      },
      {
        title: this.props.translate('STATUS'),
        titleKey: 'STATUS',
        // width: '50px',
        render: data => {
          return data?.confirmed
            ? <Tag color="green">{this.props.translate('CONFIRMED')}</Tag>
            : <Tag color="red">{this.props.translate('TO_CONFIRM')}</Tag>;
        }
      },
      {
        title: this.props.translate('ACTIONS'),
        titleKey: 'ACTIONS',
        width: '200px',
        render: data => {
          const { translate } = this.props;
          const date = moment.utc(data.startDate).startOf('day');

          return moment.utc().isBefore(date, 'day')
            && <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={this.getDeleteMessage()}
                okText={translate('DELETE')}
                cancelText={translate('CANCEL')}
                onConfirm={async () => await this.deleteSchedule(data)}>
                <Icon type="delete" />
                &nbsp;{translate('REMOVE_MEETING')}
              </Popconfirm>
            </TableButton>
        }
      }
    ],
    rows: [],
    pageSize: 15,
    currentPage: 1,
    total: 0,
    filters: {},
    date: null,
    dateRange: [],
    year: null,
    local: null,
  };

  componentDidMount = () => {
    document.body.style.overflowY = "auto";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.getWedding();
  };

  componentDidUpdate(prevProps) {
    const { translate, activeLanguage } = this.props;
    let { columns } = this.state;

    // If activeLanguage changes, then update column titles in table
    if (prevProps.activeLanguage.code !== activeLanguage.code) {
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        if (column.titleKey) column.title = translate(column.titleKey);
      }
      this.setState({ columns });
    }
  }

  getWedding = async () => {
    const { history, location } = this.props;

    const resultWedding = await GetWeddingInfo();
    const wedding = resultWedding?.success && resultWedding?.data ? resultWedding.data : this.props.wedding;

    if (wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED && location?.pathname != '/meeting-list') {
      return history.push('/meeting-list');
    } else if (wedding?.visitsBlocked == VisitsBlockedType.NONE && location?.pathname != '/visit-list') {
      return history.push('/visit-list');
    } else if (!inAgendaTest()) {
      return history.push('/404');
    }

    this.setState({ wedding }, () => this.updateDatatable());
  }

  updateDatatable = async () => {
    try {
      this.setState({ loading: true });
      const { dateRange, year, local, currentPage, pageSize } = this.state;
      let { filters } = this.state;

      if (Array.isArray(dateRange) && dateRange.length > 0) filters['dates'] = dateRange;
      else delete filters?.dates;

      if (year) filters['year'] = year;
      else delete filters?.year;

      if (local && local !== '') filters['weddingPlace'] = local;
      else delete filters?.weddingPlace;

      const result = await GetSchedulesPaginated(currentPage, pageSize, JSON.stringify(filters));
      // console.warn(`result solar`, result);

      const rows = result?.success && result?.data?.items ? result.data?.items : [];

      this.setState({
        loading: false,
        rows: rows,
        total: result?.data?.total || 0,
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  /** Messages for PopConfirms on Table */
  getDeleteMessage = () => {
    const { translate } = this.props;
    const { wedding } = this.state;

    return wedding?.status == WeddingStatus.CONFIRMED
      ? <div>
        <div>{translate('DELETE_MEETING_QUESTION')}</div>
        <div>{translate('DELETE_MEETING_INFO')}</div>
      </div>
      : <div>
        <div>{translate('DELETE_VISIT_QUESTION')}</div>
        <div>{translate('DELETE_VISIT_INFO')}</div>
      </div>
  }
  /** end Messages for PopConfirms on Table */

  deleteSchedule = async (schedule) => {
    const { translate } = this.props;

    const result = await SoftDeleteSchedule(schedule?._id, true);

    if (result?.success) {
      window.location.reload(true);
      // this.updateDatatable();
    } else {
      if (result?.message == 'NOT_AUTHORIZED_REROUTE') {
        Alerts.new({
          type: "warning",
          title: translate("ATTENTION"),
          text: translate('WAIT_RELOAD_PAGE'),
        });
        this.getAgenda();
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.message || 'GENERIC_ERROR')
        });
      }
    }
  }

  goToAgenda = () => {
    const { history, wedding } = this.props;

    if (wedding?.status == WeddingStatus.CONFIRMED) {
      return history.push('/meeting-agenda');
    } else return history.push('/visit-agenda');
  }

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { wedding } = this.state;
    const { loading } = this.state;
    const { columns, columnsWeddingConfirmed, rows } = this.state;
    const { currentPage, pageSize, total } = this.state;
    const { dateRange, year, local } = this.state;

    const showMngBtn = wedding?.status == WeddingStatus.CONFIRMED
      && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
      ? !wedding?.meetingsBlocked
      : wedding?.visitsBlocked === VisitsBlockedType.NONE;

    return (
      <Content>
        <ReserveContainer>
          <Title>{wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? translate("OUR_MEETING_LIST") : translate("OUR_VISIT_LIST")}</Title>

          <TableContent first={true}>
            <Row gutter={24} style={{ marginBottom: 10 }}>
              <Col xs={24} md={6}>
                <XpertGoRangePickerInput
                  label={translate('DATE_RANGE')}
                  allowClear={true}
                  placeholder={translate('SELECT_DATE_RANGE')}
                  input={{
                    value: dateRange,
                    onChange: value => {
                      const valueRange = Array.isArray(value) && value.length > 0
                        ? value
                        : [];
                      this.setState({
                        dateRange: valueRange,
                        year: null
                      }, () => this.updateDatatable());
                    }
                  }}
                  meta={{ valid: true }}
                  disabled={loading}
                  first={true}
                />
              </Col>
              <Col xs={24} sm={12} md={5} lg={3} xl={2}>
                <XpertGoYearInput
                  label={translate('YEAR')}
                  disabled={loading}
                  placeholder={translate('SELECT_YEAR')}
                  input={{
                    value: year,
                    onChange: value => {
                      this.setState({
                        dateRange: [],
                        year: value
                      }, () => this.updateDatatable());
                    }
                  }}
                  meta={{ valid: true }}
                  first={true}
                />
              </Col>
              {wedding?.visitsBlocked !== VisitsBlockedType.COMPLETED &&
                <Col xs={24} sm={12} md={5} lg={4} xl={4}>
                  <XpertGoSelectInput
                    label={translate('VENUE_PLACE')}
                    disabled={loading}
                    placeholder={translate('SELECT_VENUE')}
                    allowClear={true}
                    input={{
                      value: local,
                      onChange: value => {
                        this.setState({ local: value }, () => this.updateDatatable());
                      }
                    }}
                    data={VisitVenues}
                    translate={translate}
                    meta={{ invalid: false, submitFailed: false }}
                    first={true}
                  />
                </Col>}
            </Row>

            <Table
              loading={loading}
              currentPage={currentPage}
              pageSize={pageSize}
              defaultPageSize={pageSize}
              total={total}
              columns={wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? columnsWeddingConfirmed : columns}
              rows={rows}
              showHeader={true}
              emptyText={wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? translate('NO_MEETING_SCHEDULES') : translate('NO_VISIT_SCHEDULES')}
              rowKey={'_id'}
              hasPagination={true}
              handleChangePage={(currentPage) => this.handleChangePage(currentPage)}
              handleChangeRowsPerPage={(currentSize, pageSize) => this.handleChangeRowsPerPage(currentSize, pageSize)}
            />

            {showMngBtn && <SaveButton
              htmlType="button"
              text={translate("SCHEDULE_SHORT")}
              textMobile={translate("SCHEDULE_SHORT")}
              onClick={this.goToAgenda}
            />}
          </TableContent>
        </ReserveContainer>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

export default withLocalize(
  withRouter(connect(mapStateToProps)(VisitListPage))
);
