import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from "react-router-dom";
import {
  CardContainer,
  CardContent,
  Title,
  Arrow,
  Image
} from './UpgradeCardStyles';
import ArrowLeft from "../../assets/icons/ic_arrow_left.svg";

const UpgradeCard = ({ card, history, activeLanguage }) => {
  return (
    <CardContainer onClick={() => history.push(`/upgrade/${card.id}`)}>
      <CardContent>
        <Image src={card.icon?.url} />
        <Title color={card?.textColor}>{card.name[activeLanguage?.code]}</Title>
        <Arrow src={ArrowLeft} />
      </CardContent>
    </CardContainer>
  );
}

export default withLocalize(withRouter(UpgradeCard));