import React from 'react';
import { Modal, Button, Icon } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  .anticon {
    font-size: 3rem;
    margin-right: 1rem;
  }
`;

export const ModalText = styled.div`
  font-size: 15px;
`;

let DateWarningModal = ({ openModal, closeModal, onSubmit, loading, initialValues, translate, activeLanguage }) => {

  const solar = 'Solar da Levada';
  const lago = 'Quinta Lago dos Cisnes';

  // console.warn('initialValues', initialValues);

  const warningMessage = initialValues
    ? translate('WARNING_UPDATE_RESERVE_DATE')
      .replace('#oldDate#', moment.utc(initialValues.original.date).format('DD/MM/YYYY'))
      .replace('#oldPlace#', initialValues.original.weddingPlace === 2 ? lago : solar)
      .replace('#newDate#', moment.utc(initialValues.new.date).format('DD/MM/YYYY'))
      .replace('#newPlace#', initialValues.new.weddingPlace === 2 ? lago : solar)
    : '';

  return (
    <Modal
      visible={openModal}
      title={translate('ATTENTION')}
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          {translate('CANCEL')}
        </Button>,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => onSubmit(initialValues.new)}
        >
          {translate('SAVE')}
        </Button>
      ]}
    >
      {initialValues && <ModalContent>
        <Icon type='exclamation-circle' style={{color: '#faad14'}} />
        <ModalText>{warningMessage}</ModalText>
      </ModalContent>}
    </Modal>
  );
};

export default DateWarningModal;