import React from 'react';
import {
  ModalContainer,
  ModalContent,
  HeaderContainer,
  HeaderTitle,
  HeaderIcon
} from './ImageModalStyles';
import Close from '../svg/Close';
import ImageComponent from '../images/ImageComponent';

const ImageModal = ({ title, image, color, handleCancel }) => {
  return (
    <ModalContainer>
      <ModalContent
        title={title}
        visible={true}
      >
        <HeaderContainer>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderIcon onClick={() => handleCancel()}><Close fill="#ffffff"/></HeaderIcon>
        </HeaderContainer>
        <ImageComponent ratio={0.5} url={image} color={color}/>
      </ModalContent>
    </ModalContainer>
  );
}

export default ImageModal;