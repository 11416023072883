import React from 'react'

const Close = ({ fill }) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g id="Componentes-e-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Assets" transform="translate(-385.000000, -1952.000000)" fill={ fill } fillRule="nonzero">
              <g id="noun_X_2147847" transform="translate(385.000000, 1952.000000)">
                  <g id="noun_X_1099163">
                      <path d="M8.71435129,10 L0.266266538,1.55191525 C-0.0887555128,1.19689316 -0.0887555128,0.621288633 0.266266538,0.266266538 C0.621288633,-0.0887555128 1.19689316,-0.0887555128 1.55191525,0.266266538 L10,8.71435129 L18.4480847,0.266266538 C18.8031068,-0.0887555128 19.3787114,-0.0887555128 19.7337335,0.266266538 C20.0887555,0.621288633 20.0887555,1.19689316 19.7337335,1.55191525 L11.2856487,10 L19.7337335,18.4480847 C20.0887555,18.8031068 20.0887555,19.3787114 19.7337335,19.7337335 C19.3787114,20.0887555 18.8031068,20.0887555 18.4480847,19.7337335 L10,11.2856487 L1.55191525,19.7337335 C1.19689316,20.0887555 0.621288633,20.0887555 0.266266538,19.7337335 C-0.0887555128,19.3787114 -0.0887555128,18.8031068 0.266266538,18.4480847 L8.71435129,10 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default Close