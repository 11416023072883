import React from 'react';
import { Modal, Button, Icon } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { ModalContent, ModalText, ModalTextLine, ModalTextTitle } from '../agenda/DateWarningModal';

let ReserveActionEmailModal = ({ openModal, closeModal, onSubmit, loading, initialValues, translate, activeLanguage }) => {

  const actionsTranslations = {
    reactivate: 'WARNING_REACTIVATE_RESERVE',
    cancelreactivate: 'WARNING_CANCEL_REACTIVATE_RESERVE',
    remove: 'WARNING_REMOVE_RESERVE',
    confirm: 'WARNING_CONFIRM_RESERVE'
  };

  const confirmBtnTranslations = {
    reactivate: 'REACTIVATE_RESERVE',
    cancelreactivate: 'CONFIRM_CANCELATION_RESERVE',
    remove: 'CONFIRM_CANCELATION_RESERVE',
    confirm: 'CONFIRM_RESERVE'
  };

  const actionsIcon = {
    reactivate: 'check-circle',
    cancelreactivate: 'close-circle',
    remove: 'delete',
    confirm: 'carry-out'
  }

  const actionsIconColor = {
    reactivate: '#98e473',
    cancelreactivate: '#ff6b73',
    remove: '#ff6b73',
    confirm: '#98e473'
  }

  const solar = 'Solar da Levada';
  const lago = 'Quinta Lago dos Cisnes';

  const warningMessage = initialValues
    ? translate(actionsTranslations[initialValues?.action])
      .replace('#date#', moment.utc(initialValues?.reserve?.date).format('DD/MM/YYYY'))
      .replace('#local#', initialValues?.reserve?.weddingPlace === 2 ? lago : solar)
    : '';

  return (
    <Modal
      visible={openModal}
      title={translate('ATTENTION')}
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          {translate('CANCEL_ACTION_RESERVE')}
        </Button>,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => onSubmit(initialValues)}
        >
          {initialValues ? translate(confirmBtnTranslations[initialValues.action]) : translate('CONFIRM')}
        </Button>
      ]}
    >
      {initialValues && <ModalContent>
        <Icon type={actionsIcon[initialValues.action]} style={{ color: actionsIconColor[initialValues.action] }} />
        <ModalText>{warningMessage}</ModalText>
      </ModalContent>}
    </Modal>
  );
};

export default ReserveActionEmailModal;