import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import Alerts from '../../components/alert/Alert';
import BaseButton from '../../components/buttons/BaseButton';
import { StatsContent, StatsContentRow, StatsTotal, TableCategory, TableGuests, TableGuestsHeader, TableGuestsOptions, TableGuestsStats, TableName, TablePurpose, TableSeatCode } from './RoomPlanStyles';
import { convertNumberToLetter } from './utilRoomPlan';
import RadioButtons from '../../components/inputs/RadioButtons';
import GuestSeatComponent from './GuestSeatComponent';
import { MaterialInput } from '../../components/inputs/MaterialStyles';
import { GuestAgeEnum, TablePurposeEnum } from './GuestTablesComponent';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import CoupleIcon from '../../assets/icons/sections/about_us.svg';
import StaffIcon from '../../assets/icons/sections/staff.svg';
import ReactSVG from 'react-svg';
import { Element } from 'react-scroll';
import OrderSeatsModal from './OrderSeats';

const { confirm } = Modal;

const GuestTableComponent = ({
  index,
  table,
  validated,
  roomPlanClosed,
  disableTable,
  resizable,
  increasable,
  objectTypes,
  foodRestrictions,
  parentActiveTable,
  parentEditTableCouple,
  parentChangeTablePurpose,
  parentUpdateTable,
  parentSelectToChangeGuests,
  parentUnselectChange,
  parentOpenImportModal,
  translate,
  activeLanguage
}) => {
  const [tableObj, setTable] = useState(table);
  const [name, setName] = useState(table?.name);
  const [code, setCode] = useState(table?.code);
  const [active, setActive] = useState(table?.active);
  const [tableClass, setTableClass] = useState(table?.tableClass);
  const [tableType, setTableType] = useState(table?.tableType);
  const [tablePurpose, setTablePurpose] = useState(table?.tablePurpose);
  const [seats, setSeats] = useState(table?.seats);
  const [seatsFrontCouple, setSeatsFrontCouple] = useState(table?.seatsFrontCouple);
  const [isSelectedToChange, setSelectedToChange] = useState(table?.isSelectedToChange);
  const [seatStats, setSeatStats] = useState({ ADULT: 0, CHILD: 0, BABY: 0, NEWBORN: 0 });

  const [isValidated, setValidated] = useState(validated);
  const [isRoomPlanClosed, setRoomPlanClosed] = useState(roomPlanClosed);

  const [canDisable, setCanDisable] = useState(disableTable);
  const [canResize, setCanResize] = useState(resizable);
  const [canIncrease, setCanIncrease] = useState(increasable);

  const [resizing, toggleResize] = useState(false);
  const [addingSeat, toggleAddingSeat] = useState(false);
  const [showOrderModal, toggleOrderModal] = useState(false);
  const [savingOrder, toggleSavingOrder] = useState(false);

  useEffect(() => {
    setTable(table);
    setName(table?.name);
    setCode(table?.code);
    setActive(table?.active);
    setTableClass(table?.tableClass);
    setTableType(table?.tableType);
    setTablePurpose(table?.tablePurpose);
    setSelectedToChange(table?.isSelectedToChange);
    setSeats(table?.seats);
    setSeatsFrontCouple(table?.seatsFrontCouple);
  }, [table]);

  useEffect(() => {
    setCanDisable(disableTable);
  }, [disableTable]);

  useEffect(() => {
    setValidated(validated);
  }, [validated]);

  useEffect(() => {
    setRoomPlanClosed(roomPlanClosed);
  }, [roomPlanClosed]);

  useEffect(() => {
    setCanResize(resizable);
  }, [resizable]);

  useEffect(() => {
    setCanIncrease(increasable);
  }, [increasable]);

  useEffect(() => {
    setSeatStats({
      ADULT: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.ADULT)?.length || 0,
      CHILD: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.CHILD)?.length || 0,
      BABY: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.BABY)?.length || 0,
      NEWBORN: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.NEWBORN)?.length || 0,
    });
  }, [seats])

  const checkCoupleTable = () => {
    return tablePurpose == TablePurposeEnum.COUPLE;
  }

  const checkStaffTable = () => {
    return tablePurpose == TablePurposeEnum.STAFF;
  }

  const checkGuestTable = () => {
    return tablePurpose == TablePurposeEnum.GUEST || tablePurpose == TablePurposeEnum.CHILDREN;
  }

  const activateTable = () => {
    setActive(true);
    parentActiveTable(code);
  }

  const confirmInactiveTable = () => {
    if (isValidated || isRoomPlanClosed || !canDisable) return;

    if (tablePurpose == TablePurposeEnum.COUPLE || tablePurpose == TablePurposeEnum.STAFF) {
      return Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_INACTIVE_TABLE')
      });
    } else {
      confirm({
        title: translate('DISABLE_TABLE_QUESTION'),
        content: (<div>
          <p>{translate('DISABLE_TABLE_CONTENT')}</p>
        </div>
        ),
        okType: 'danger',
        okText: translate('DISABLE'),
        cancelText: translate('CANCEL'),
        onOk: () => {
          setActive(false);
          parentActiveTable(code)
        },
        onCancel: () => null,
      });
    }
  }

  const handleMenuClick = (e) => {
    const key = parseInt(e.key);
    if (key == 0 && checkCoupleTable()) parentEditTableCouple();
    else if (key == 1) activateTable()
    else if (key == 2) parentOpenImportModal(code);
    else if (key == 3) toggleOrderModal(true);
    // else if (key == 2) increaseTable();
    // else if (key == 3) reduceTable();
    // else if (key == 4) selectToChangeGuests();
    else if (key == 5) confirmInactiveTable();
  }

  const createMenu = () => {
    return <Menu onClick={(e) => handleMenuClick(e)}>
      {checkCoupleTable() && !isValidated && !isRoomPlanClosed && active && <Menu.Item key="0">
        {translate('EDIT')}
      </Menu.Item>}
      {!checkCoupleTable() && !isValidated && !isRoomPlanClosed && !active && <Menu.Item key="1">
        {translate('ENABLE')}
      </Menu.Item>}
      {active && <Menu.Item key="2">
        {translate('IMPORT')}
      </Menu.Item>}
      {active && <Menu.Item key="3">
        {translate('TO_ORDER')}
      </Menu.Item>}
      {/* {canResize && canIncrease && <Menu.Item key="2">
        Aumentar
      </Menu.Item>}
      {canResize && !canIncrease && <Menu.Item key="3">
        Reduzir
      </Menu.Item>} */}
      {/* {checkGuestTable(index) && !table?.isSelectedToChange && <Menu.Item key="4">
        Trocar Convidados
      </Menu.Item>} */}
      {!checkCoupleTable() && active && canDisable && <Menu.Item key="5">
        {translate('DISABLE')}
      </Menu.Item>}
    </Menu >
  };

  const showMenu = () => {
    const showEdit = checkCoupleTable() && active ? true : false;
    const showEnable = !checkCoupleTable() && !active ? true : false;
    const showImport = active;
    const showDisable = !checkCoupleTable() && active && canDisable ? true : false;
    return ((showEdit || showEnable || showImport || showDisable) && !isValidated && !isRoomPlanClosed) ? true : false;
  }

  const changeSeatsFrontCouple = (event) => {
    if (event?.target?.value == false) {
      const seatsFront = seats.filter(f => f?.frontCouple);
      if (seatsFront.find(f => f?.guestName?.trim() != '')) {
        return Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('SEATS_FRONT_COUPLE_FILLED')
        });
      }
    }

    setSeatsFrontCouple(event?.target?.value);
    parentUpdateTable({
      type: 'UPDATE_SEATS_FRONT_COUPLE',
      code,
      value: event.target?.value
    });
  }

  const changePurpose = (event) => {
    setTablePurpose(event?.target?.value);
    if (event?.target?.value == TablePurposeEnum.STAFF) {
      parentChangeTablePurpose(event?.target?.value);
    } else {
      parentUpdateTable({
        type: 'UPDATE_TABLE_PURPOSE',
        code,
        value: event.target?.value
      });
    }
  }

  const addSeat = () => {
    const updatedSeats = [...seats];
    toggleAddingSeat(true);
    const seat = {
      number: seats?.length,
      couple: false,
      guestName: '',
      guestAge: GuestAgeEnum.ADULT,
      foodRestrictions: []
    };
    updatedSeats.push(seat);
    setSeats(updatedSeats);
    parentUpdateTable({
      type: 'ADD_TABLE_SEATS',
      code,
      value: seat
    });
    toggleAddingSeat(false);
  };

  const showChangeGuestsButton = () => {
    return !isSelectedToChange
      ? <BaseButton
        type='default'
        text={translate('CHANGE_GUESTS')}
        disabled={isValidated || isRoomPlanClosed || !active}
        onClick={() => parentSelectToChangeGuests()} />
      : <BaseButton
        type='danger'
        text={translate('CANCEL_CHANGE_GUESTS')}
        disabled={isValidated || isRoomPlanClosed || !active}
        onClick={() => unselectToChangeGuests()} />;
  }

  const unselectToChangeGuests = () => {
    setSelectedToChange(!isSelectedToChange);
    parentUnselectChange();
  }

  const showResizeButton = () => {
    return canIncrease
      ? <BaseButton
        type='default'
        text={translate('INCREASE_TABLE')}
        disabled={isValidated || isRoomPlanClosed || !active}
        loading={resizing}
        onClick={() => increaseTable()} />
      : <BaseButton
        type='default'
        text={translate('REDUCE_TABLE')}
        disabled={isValidated || isRoomPlanClosed || !active}
        loading={resizing}
        onClick={() => reduceTable()} />;
  }

  const increaseTable = () => {
    const updatedSeats = [...seats];
    let updatedTable = { ...table };

    const objectType = objectTypes.find(f => f?.tableClass == 'RectangularLTable');
    if (objectType) {
      toggleResize(true);
      updatedTable.tableClass = objectType?.tableClass;
      updatedTable.tableType = objectType?.tableClass;
      const missingSeats = Math.abs(objectType?.maxSeats - updatedSeats?.length);
      for (let i = 0; i < missingSeats; i++) {
        const seat = {
          number: updatedSeats?.length,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
        updatedSeats.push(seat);
      }

      updatedTable = { ...updatedTable, seats: updatedSeats };
      setTable(updatedTable);
      parentUpdateTable({
        type: 'UPDATE_TABLE',
        code,
        value: updatedTable
      });
      toggleResize(false);
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('GENERIC_ERROR')
      });
    }
  }

  const reduceTable = () => {
    let updatedSeats = [...seats];
    let updatedTable = { ...table };
    const objectType = objectTypes.find(f => f?.tableClass == 'RectangularTable');
    if (objectType) {

      const filledSeats = updatedSeats?.filter(f => f?.guestName?.trim() != '').length || 0;
      if (filledSeats > objectType?.maxSeats) {
        return Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate('ERROR_REDUCE_TABLE')
        });
      } else {
        toggleResize(true);
        updatedTable.tableClass = objectType?.tableClass;
        updatedTable.tableType = objectType?.tableClass;
        const seatsToRemove = Math.abs(objectType?.maxSeats - updatedSeats?.length);
        for (let i = 0; i < seatsToRemove; i++) {
          const indexToDelete = updatedSeats.findIndex(f => f?.guestName?.trim() == '');
          updatedSeats.splice(indexToDelete, 1);
        }

        updatedSeats = updatedSeats.map((m, index) => ({ ...m, number: index }));
        updatedTable = { ...updatedTable, seats: updatedSeats }
        setTable(updatedTable);
        parentUpdateTable({
          type: 'UPDATE_TABLE',
          code,
          value: updatedTable
        });
        toggleResize(false);
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('GENERIC_ERROR')
      });
    }
  }

  const onSubmitOrder = async values => {
    toggleSavingOrder(true);

    if (values.length > 0) {
      let orderedList = [];
      Object.keys(values).forEach(function (key) {
        let obj = { ...values[key], number: key };
        orderedList.push(obj);
      });

      if (orderedList && Array.isArray(orderedList) && orderedList.length > 0) {
        let updatedSeats = [...orderedList];
        updatedSeats = updatedSeats.map((seat, index) => ({
          ...seat,
          number: index
        }))
        setSeats(updatedSeats);
        parentUpdateTable({
          type: 'UPDATE_SEATS',
          code,
          value: updatedSeats
        });
      }
    }
    toggleSavingOrder(false);
    toggleOrderModal(false);
  }

  const cancelOrderModal = () => {
    toggleOrderModal(false);
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
    parentUpdateTable({
      type: 'UPDATE_TABLE_NAME',
      code,
      value: e.target.value,
    });
  }

  const handleUpdateSeat = (type, seatIndex, updatedSeat) => {
    const updatedSeats = [...seats];
    updatedSeats.splice(seatIndex, 1, updatedSeat);
    setSeats(updatedSeats);
    parentUpdateTable({
      type,
      code,
      seatIndex,
      value: updatedSeat,
    });
  }

  const handleRemoveSeat = (seatIndex) => {
    let updatedSeats = [...seats];
    updatedSeats.splice(seatIndex, 1);
    updatedSeats = updatedSeats.map((seat, index) => ({
      ...seat,
      number: index
    }))
    setSeats(updatedSeats);
    parentUpdateTable({
      type: 'UPDATE_SEATS',
      code,
      value: updatedSeats
    });
  }

  const showAlert = () => {
    if (isValidated) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('ROOM_PLAN_APPROVED')
      });
    }
    else if (isRoomPlanClosed) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        // text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }


  return (
    <React.Fragment>
      {active && <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} style={{ minHeight: '100%', display: 'flex' }}>
        <Element
          name={`anchor-${code}`}
          key={`anchor-${code}`}
          id={`anchor-${code}`}
          style={{ width: '100%', height: '100%' }}
        >
          <TableGuests disabled={!active} staff={checkStaffTable()} couple={checkCoupleTable()}>

            {/* <TableGuestsType>
                <div className='guest-table-content'>
                <div className={`guest-table ${getTableClassType(index)}`}></div>
                <div>{getTableType(index)}</div>
                </div>
              </TableGuestsType> */}
            {(checkCoupleTable() || checkStaffTable()) &&
              <TableCategory staff={checkStaffTable() ? true : false}>
                <ReactSVG
                  className='category-icon'
                  src={checkStaffTable() ? StaffIcon : CoupleIcon} />
                <span>{checkStaffTable() ? translate('STAFF_TABLE') : translate('COUPLE_TABLES')}</span>
              </TableCategory>
            }

            <TableGuestsHeader>
              <TableSeatCode seat={false} modal={false} couple={checkCoupleTable()}>
                {convertNumberToLetter(code || 0) || ''}
              </TableSeatCode>
              <TableName>
                <MaterialInput
                  placeholder={translate('TABLE_THEME')}
                  type="text"
                  readOnly={isValidated || isRoomPlanClosed || !active}
                  noLabel={true}
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                  onClick={() => showAlert()} />
              </TableName>
              {showMenu() && <Dropdown overlay={() => createMenu()} trigger={['click']}>
                <i style={{ width: 31, textAlign: 'center', fontSize: 20 }} className="fa-solid fa-ellipsis-vertical"></i>
              </Dropdown>}
            </TableGuestsHeader>

            {checkCoupleTable() && seats?.length >= 8
              ? <TablePurpose>
                <InputLabelDiv horizontal={true}>{translate('GUESTS_FRONT_COUPLE')}</InputLabelDiv>
                <RadioButtons
                  // label={`${translate('GUESTS_FRONT_COUPLE')}:`}
                  meta={{ invalid: false, submitFailed: false }}
                  first={true}
                  options={[
                    { label: translate('YES'), value: true },
                    { label: translate('NO'), value: false },
                  ]}
                  disabled={isValidated || isRoomPlanClosed || !active}
                  input={{
                    value: seatsFrontCouple,
                    onChange: (e) => changeSeatsFrontCouple(e)
                  }}
                />
              </TablePurpose>
              : !checkCoupleTable() && !checkStaffTable() ? <TablePurpose>
                <InputLabelDiv horizontal={true}>{translate('TYPE_TABLES')}:</InputLabelDiv>
                <RadioButtons
                  // label={`${translate('TYPE_TABLES')}:`}
                  meta={{ invalid: false, submitFailed: false }}
                  first={true}
                  options={[
                    { label: translate('ROOM_PLAN_GUESTS'), value: TablePurposeEnum.GUEST },
                    { label: translate('ROOM_PLAN_CHILD'), value: TablePurposeEnum.CHILDREN },
                  ]}
                  disabled={isValidated || isRoomPlanClosed || !active}
                  input={{
                    value: tablePurpose,
                    onChange: (e) => changePurpose(e)
                  }}
                />
              </TablePurpose>
                : null}

            {seats?.map((seat, sindex) => (
              <GuestSeatComponent
                validated={isValidated}
                roomPlanClosed={isRoomPlanClosed}
                key={sindex}
                index={sindex}
                seat={seat}
                table={tableObj}
                foodRestrictions={foodRestrictions}
                parentUpdateSeat={handleUpdateSeat}
                parentRemoveSeat={handleRemoveSeat}
                parentUpdateTable={parentUpdateTable}
                translate={translate}
                activeLanguage={activeLanguage}
              />
            ))}

            <TableGuestsOptions>
              {checkStaffTable() && <BaseButton
                type='default'
                icon={'plus'}
                loading={addingSeat}
                text={translate('ADD_SEAT')}
                disabled={isValidated || isRoomPlanClosed || !active}
                onClick={() => addSeat()} />}
              {checkGuestTable() && active && showChangeGuestsButton()}
              {canResize && showResizeButton()}
            </TableGuestsOptions>
            <TableGuestsStats disabled={!active}>
              <StatsContent>
                <StatsContentRow>
                  <div className='stats-description'>{translate('ADULT_AGE')}</div>
                  <div className='stats-value'>{seatStats?.ADULT || 0}</div>
                </StatsContentRow>
                <StatsContentRow>
                  <div className='stats-description'>{translate('CHILD_AGE')}</div>
                  <div className='stats-value'>{seatStats?.CHILD || 0}</div>
                </StatsContentRow>
                <StatsContentRow>
                  <div className='stats-description'>{translate('BABY_AGE')}</div>
                  <div className='stats-value'>{seatStats?.BABY || 0}</div>
                </StatsContentRow>
                <StatsContentRow>
                  <div className='stats-description'>{translate('NEWBORN_AGE')}</div>
                  <div className='stats-value'>{seatStats?.NEWBORN || 0}</div>
                </StatsContentRow>
              </StatsContent>
              <StatsTotal couple={checkCoupleTable()}>
                <div className='pax'>{(seatStats?.ADULT || 0) + (seatStats?.CHILD || 0)}</div>
                {(seatStats?.BABY || 0) > 0 &&
                  <div className='stroller'>{seatStats?.BABY || 0} {translate('BABY_SEAT')}</div>}
                {(seatStats?.NEWBORN || 0) > 0 &&
                  <div className='stroller'>{seatStats?.NEWBORN || 0} {translate('BABY_CAR')}</div>}
              </StatsTotal>
            </TableGuestsStats>
          </TableGuests>
        </Element>

        {showOrderModal &&
          <OrderSeatsModal
            open={showOrderModal}
            loading={savingOrder}
            onSubmit={onSubmitOrder}
            closeModal={cancelOrderModal}
            initialValues={[...seats]}
            isStaff={checkStaffTable()}
            hasSeatsFrontCouple={seatsFrontCouple}
            translate={translate}
          />}

      </Col>}
    </React.Fragment>
  );
};


export default React.memo(GuestTableComponent, (props, nextProps) => {
  // console.log("GuestTableComponent " + convertNumberToLetter(props.table.code));
  // console.log("GuestTableComponent " + props.table.name);
  // console.log({ props, nextProps });

  if (props.table == nextProps.table
    && props.table.seats.filter(s => s.guestName?.trim() != '').length == nextProps.table.seats.filter(s => s.guestName?.trim() != '').length
    && props.table.seatsFrontCouple == nextProps.table.seatsFrontCouple
    && props?.disableTable == nextProps?.disableTable
    && props?.resizable == nextProps?.resizable
    && props?.increasable == nextProps?.increasable
    && props?.validated == nextProps?.validated
    && props?.roomPlanClosed == nextProps?.roomPlanClosed
    && props?.activeLanguage?.code == nextProps?.activeLanguage?.code
  ) {
    return true;
  }

  //re-render
  // console.log('re-render table', nextProps.table?.name);
  return false;
});
