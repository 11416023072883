import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  ReservesBlock,
} from "../../infra/requests/MyWeddingRequests";
import {
  Title,
  Subtitle,
  ReserveContainer,
  TableHeader,
} from "./ReserveStyles";
import moment from "moment";
import { SpinLoading, TableButton } from "../../styles/BasicStyles";
import { ConfirmReserve, GetReserve, GetReserves, GetReservesByPlace, ReactivateReserve, SoftDeleteReserve } from "../../infra/requests/ReserveRequests";
import { locals } from "../agenda/AgendaPage";
import { TableContent } from "../agenda/ReserveInfoModal";
import Table from "../../components/table/Table";
import OrdinalNumberComponent from "../../components/ordinalNumber/OrdinalNumberComponent";
import { Content } from "../agenda/AgendaStyles";
import { Icon, Popconfirm, Tooltip } from "antd";
import Alerts from "../../components/alert/Alert";
import CurrencyComponent from "../../components/currency/CurrencyComponent";
import DateInput from "../../components/inputs/DateInput";
import ReserveActionEmailModal from "./ReserveActionEmailModal";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";

export const ReserveStatus = {
  OCCUPIED: 'O', // O: Date Occupied
  SINALIZATION: 'S', // S: Date in process of sinalization
  RSC: 'RSC', // RSC: Reserve without compromisse
  INACTIVE: 'I', // I: Inactive
  ARCHIVED: 'A', // A: Archived
  // DELETED: 'D', // D: Deleted
};

const TableActions = {
  'EDIT': 1,
  'REACTIVATE': 2,
  'CONFIRM': 3,
  'DELETE': 4,
}

class ConfirmReservePage extends Component {
  state = {
    loading: false,
    wedding: {},
    loadingSolar: false,
    loadingLago: false,
    columns: [
      // {
      //   title: this.props.translate('WAITING_LIST'),
      //   titleKey: 'WAITING_LIST',
      //   // width: '10px',
      //   // className: 'align-right',
      //   render: data => {
      //     const { activeLanguage } = this.props;
      //     return data.status === ReserveStatus.INACTIVE ? '' : <OrdinalNumberComponent value={data.order} activeLanguage={activeLanguage} />
      //   }
      // },
      {
        title: this.props.translate('WEEKDAY'),
        titleKey: 'WEEKDAY',
        // width: '50px',
        render: data => {
          const { activeLanguage } = this.props;
          return moment.utc(data.date).locale(activeLanguage.code).format('dddd')
        }
      },
      {
        title: this.props.translate('DATE'),
        titleKey: 'DATE',
        // width: '50px',
        render: data => {
          return moment.utc(data.date).format('DD-MM-YYYY')
        }
      },
      // {
      //   title: this.props.translate('PAX'),
      //   titleKey: 'PAX',
      //   // width: '30px',
      //   // className: 'align-right',
      //   render: data => {
      //     const now = moment();
      //     const reserveIsActive = now.isSameOrBefore(moment(data.endActivationDate));
      //     return reserveIsActive
      //       ? data.priceObj.minPax
      //       : data.price.minPax;
      //   }
      // },
      // {
      //   title: this.props.translate('PRICE'),
      //   titleKey: 'PRICE',
      //   // width: '30px',
      //   // className: 'align-right',
      //   render: data => {
      //     const now = moment();
      //     const reserveIsActive = now.isSameOrBefore(moment(data.endActivationDate));
      //     const price = reserveIsActive
      //       ? data.priceObj.minPrice
      //       : data.price.minPrice;
      //     return <CurrencyComponent value={price} />
      //   }
      // },
      // {
      //   title: this.props.translate('MINIMUM_GLOBAL_PRICE'),
      //   titleKey: 'MINIMUM_GLOBAL_PRICE',
      //   // width: '60px',
      //   // className: 'align-right',
      //   render: data => {
      //     const now = moment();
      //     const reserveIsActive = now.isSameOrBefore(moment(data.endActivationDate));
      //     const price = reserveIsActive
      //       ? data.priceObj.minPrice
      //       : data.price.minPrice;
      //     const pax = reserveIsActive
      //       ? data.priceObj.minPax
      //       : data.price.minPax;
      //     return <CurrencyComponent value={Number((price * pax).toFixed(2))} />
      //   }
      // },
      {
        title: this.props.translate('ACTIONS'),
        titleKey: 'ACTIONS',
        titleBlocked: 'STATUS',
        width: '200px',
        render: data => {
          const { translate } = this.props;
          const { wedding } = this.state;

          // Check if there are 1 day or less left to end the reservation activation period
          const now = moment.utc();
          const canAction = now.isSameOrBefore(moment.utc(data.date), 'day');
          const isActive = now.isSameOrBefore(moment.utc(data.endActivationDate));
          const status = data?.status && data?.status === ReserveStatus.SINALIZATION
            ? translate('IN_SIGNAGE')
            : data.status === ReserveStatus.OCCUPIED
              ? translate('CONFIRMED')
              : '';

          return wedding && wedding?.reservesBlocked
            ? status
            : <React.Fragment>
              {data?.status === ReserveStatus.RSC && data?.order === 1 && isActive && canAction &&
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={this.getConfirmMessage()}
                    okText={translate('CONFIRM')}
                    cancelText={translate('CANCEL')}
                    // onConfirm={async () => await this.reserveActions(TableActions.CONFIRM, data)}>
                    onConfirm={async () => await this.confirmReserve(data)}>
                    <Icon type="carry-out" />
                    &nbsp;{translate('CONFIRM_RESERVE')}
                  </Popconfirm>
                </TableButton>}
            </React.Fragment >
        }
      }
    ],
    rowsSolar: [],
    filtersSolar: { weddingPlace: 1, confirmation: true },
    dateSolar: null,
    rowsLago: [],
    filtersLago: { weddingPlace: 2, confirmation: true },
    dateLago: null,

    showActionEmailModal: false,
    dataActionEmail: null,
    loadingActionEmail: false,
  };

  componentDidMount = () => {
    const { location, wedding } = this.props;

    document.body.style.overflowY = "auto";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    this.loadComponent();
  };

  componentDidUpdate(prevProps) {
    const { translate, activeLanguage } = this.props;
    let { columns } = this.state;

    // If activeLanguage changes, then update column titles in table
    if (prevProps.activeLanguage.code !== activeLanguage.code) {
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        if (column.titleKey) column.title = translate(column.titleKey);
      }
      this.setState({ columns });
    }
  }

  loadComponent = async () => {
    const { translate } = this.props;
    const { wedding } = this.state;
    let { columns } = this.state;

    // If wedding.reservesBlocked === true, then update action column to status column
    if (wedding && wedding.reservesBlocked) {
      if (columns[columns.length - 1].titleBlocked) columns[columns.length - 1].title = translate(columns[columns.length - 1].titleBlocked);
      this.setState({ columns });
    }

    await this.updateDatatableSolar();
    await this.updateDatatableLago();
    this.setState({ loading: false });
  }

  updateDatatableSolar = async () => {
    try {
      this.setState({ loadingSolar: true });
      const { filtersSolar, dateSolar } = this.state;

      if (dateSolar !== null) filtersSolar['date'] = dateSolar;
      else delete (filtersSolar.date);

      const result = await GetReserves(JSON.stringify(filtersSolar));
      // console.warn(`result solar`, result);

      const rows = result.success && result.data ? result.data : [];

      this.setState({
        loadingSolar: false,
        rowsSolar: rows,
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingSolar: false });
    }
  }

  updateDatatableLago = async () => {
    try {
      this.setState({ loadingLago: true });
      const { filtersLago, dateLago } = this.state;

      if (dateLago !== null) filtersLago['date'] = dateLago;
      else delete (filtersLago.date);

      const result = await GetReserves(JSON.stringify(filtersLago));
      // console.warn('result lago', result);

      const rows = result.success && result.data ? result.data : [];

      this.setState({
        loadingLago: false,
        rowsLago: rows
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, loadingLago: false });
    }
  }


  /** Messages for PopConfirms on Table */
  getConfirmMessage = () => {
    const { translate } = this.props;

    return <div>
      <div>{translate('CONFIRM_RESERVE_QUESTION')}</div>
      <div>{translate('CONFIRM_RESERVE_INFO')}</div>
    </div>
  }
  /** end Messages for PopConfirms on Table */

  confirmReserve = async (reserve) => {
    const { translate, dispatch } = this.props;

    const result = await ConfirmReserve(reserve._id);

    if (result.success) {
      this.setState({ wedding: result?.data?.wedding }, () => this.loadComponent());
      window.location.reload(true);
    } else {
      if (result?.data === 'ERROR_DATE_OCCUPIED' || result?.data === 'ERROR_CANT_CONFIRM') {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.data)
        });
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: result?.data
        });
      }
    }
  }

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { wedding } = this.props;
    const { loading, loadingLago, loadingSolar } = this.state;
    const { columns, rowsSolar, rowsLago } = this.state;
    const { dateSolar, dateLago } = this.state;
    const { showActionEmailModal, dataActionEmail, loadingActionEmail } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <Content>
        <ReserveContainer>
          <Title>{translate("CONFIRMATIONS")}</Title>

          {locals.map((local, index) => (
            <TableContent key={index} first={index === 0}>
              <TableHeader first={index === 0}>
                <Subtitle>{translate("RESERVES")} {local.name}</Subtitle>
                {/* <div className="datepicker">
                  <DateInput
                    input={{
                      value: local._id === 2 ? dateLago : dateSolar,
                      onChange: (value) => {
                        if (local._id === 2) {
                          this.setState({ dateLago: value !== '' ? moment(value, 'DD-MM-YYYY') : null },
                            () => this.updateDatatableLago());
                        } else {
                          this.setState({ dateSolar: value !== '' ? moment(value, 'DD-MM-YYYY') : null },
                            () => this.updateDatatableSolar());
                        }
                      }
                    }}
                    first={true}
                    meta={{ invalid: false, submitFailed: false }} />
                </div> */}
              </TableHeader>

              <Table
                loading={loading || (local._id === 2 ? loadingLago : loadingSolar)}
                columns={columns}
                rows={local._id === 2 ? rowsLago : rowsSolar}
                showHeader={true}
                emptyText={translate('NO_RESERVES_BY_SPACE')}
                rowKey={'id'}
                hasPagination={false}
              />
            </TableContent>
          ))}
        </ReserveContainer>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

export default withLocalize(
  withRouter(connect(mapStateToProps)(ConfirmReservePage))
);
