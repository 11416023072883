import client from '../config/AxiosConfig';

export const CreateWaitingDate = async (data) =>
  client.put(`/my-wedding/createWaitingDate`, data);

export const CreateMultipleWaitingDate = async (data) =>
  client.put(`/my-wedding/createMultipleWaitingDate`, data);

// export const UpdateReserve = async (data) =>
//   client.post(`/my-wedding/updateReserve`, data);

export const SoftDeleteWaitingDate = async (id, deleted) =>
  client.delete(`/my-wedding/softDeleteWaitingDate/${id}/${deleted}`);

export const softDeleteWaitingDateAgenda = async (deleted, data) =>
  client.post(`/my-wedding/softDeleteWaitingDateAgenda/${deleted}`, data);

export const SoftDeleteMultipleWaitingDate = async (deleted, data) =>
  client.post(`/my-wedding/softDeleteMultipleWaitingDate/${deleted}`, data);

export const GetWeddingWaitingDates = async (filters) =>
  client.get(`/my-wedding/getWeddingWaitingDates?filter=${filters}`);

  export const GetWaitingDatesPaginated = async (currentPage, limit, filters) =>
  client.get(`/my-wedding/getWaitingDates/${currentPage}/${limit}?filter=${filters}`);


// export const GetWaitingDate = async (id) =>
//   client.get(`/my-wedding/getWaitingDate/${id}`);