import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyComponent = ({value}) => {
  return (
    <NumberFormat
      value={value || undefined}
      defaultValue={'00.00'}
      displayType={'text'}
      suffix="€"
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

CurrencyComponent.propTypes = {
  value: PropTypes.number
};

export default CurrencyComponent;
