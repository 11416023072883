import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DefaultImage from "../../assets/default_image.png";
import EnlargeIcon from "../../components/svg/Enlarge";
import { ImageIcon } from "../../components/optionCard/OptionCardStyles";

const ImageContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
`;

const ImageComponent = ({ width, ratio, children, color, url, bgContain, onClick, zoom }) => {
  return (
    <ImageContainer onClick={onClick}>
      {zoom ? <ImageIcon>
        <EnlargeIcon />
      </ImageIcon>
        : null}
      <Image
        style={{
          width: width ? width : "100%",
          paddingTop: ratio ? 100 * ratio + "%" : url ? "100%" : '0%',
          backgroundColor: url ? 'none' : color ? color : "#ece8e2",
          backgroundImage: url ? `url(${url})` : `url(${DefaultImage})`,
          backgroundSize: bgContain || !url ? 'contain' : 'cover',
        }}
      //onClick={onClick}
      />
      {children}
    </ImageContainer>
  );
};

ImageComponent.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.any
};
export default ImageComponent;
