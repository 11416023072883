import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import GuestTableComponent from './GuestTableComponent';
import BaseButton from '../../components/buttons/BaseButton';
import { BtnAddTable } from './RoomPlanStyles';

export const GuestAgeEnum = {
  NEWBORN: 'NEWBORN', // Baby: from 0 months to 5 months old
  BABY: 'BABY', // Baby: from 6 months to 2 years old
  CHILD: 'CHILD', // Child: from 3 to 7 years old
  ADULT: 'ADULT' // Adult: 8 years old or above
}

export const AgeTypes = [
  { _id: 'NEWBORN', name: 'NEWBORN_AGE' },
  { _id: 'BABY', name: 'BABY_AGE' },
  { _id: 'CHILD', name: 'CHILD_AGE' },
  // { _id: 'ADULT', name: 'ADULT_AGE' },
];

export const TablePurposeEnum = {
  GUEST: 'GUEST',
  STAFF: 'STAFF',
  CHILDREN: 'CHILD',
  COUPLE: 'COUPLE'
}

export const TableTypesEnum = {
  OVAL: 'OVAL',
  ROUND: 'ROUND',
  SQUARE: 'SQUARE',
  RECTANGULAR: 'RECTANGULAR'
}

export const SidesEnum = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  ND: 'ND',
}

const GuestTablesComponent = ({
  isValidated,
  roomPlanBlocked,
  canAddTable,
  tables,
  objectTypes,
  foodRestrictions,
  parentActiveTable,
  parentDeleteTable,
  editTableCouple,
  updateTable,
  changeTablePurpose,
  parentSelectToChangeGuests,
  parentCancelChangeGuests,
  parentOpenImportModal,
  translate,
  activeLanguage
}) => {
  const [validated, setValidated] = useState(isValidated);
  const [roomPlanClosed, setRoomPlanClosed] = useState(roomPlanBlocked);
  const [showAdd, setShowAdd] = useState(canAddTable);
  const [addingTable, setAdding] = useState(false);

  useEffect(() => {
    setValidated(isValidated);
  }, [isValidated]);

  useEffect(() => {
    setRoomPlanClosed(roomPlanBlocked);
  }, [roomPlanBlocked]);

  useEffect(() => {
    setShowAdd(canAddTable);
  }, [canAddTable]);


  const addTable = () => {
    if (validated || roomPlanClosed || !showAdd) return;

    setAdding(true);

    const listActives = tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF));
    listActives.sort((a, b) => a?.code - b?.code);
    const lastTableActive = listActives?.length > 0
      ? listActives[listActives.length - 1]
      : null;
    const tablesToActive = tables?.filter(f => !f?.active && !f?.removeBecauseOfOvalL && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF))
      .filter(f => f?.code > lastTableActive?.code);
    tablesToActive.sort((a, b) => a?.code - b?.code);
    const nextTableToActive = tablesToActive?.length > 0
      ? tablesToActive[0]
      : null;

    if (nextTableToActive && nextTableToActive?.code) {
      parentActiveTable(nextTableToActive?.code);
    }
    setAdding(false);
  }

  // const canResizeTable = (table) => {
  //   const { tables } = this.state;
  //   const increasedTables = tables.filter(f => f?.active && f?.tableClass == 'RectangularLTable');

  //   if (increasedTables?.length == 2) {
  //     return increasedTables.find(f => f?.code == table?.code) ? true : false;
  //   } else {
  //     return (table?.tableType == 'RectangularTable' || table?.tableType == 'RectangularLTable') && increasedTables?.length < 2;
  //   }
  // }

  const canResize = (table) => {
    // Resizable tables
    if (table?.tableType == 'RectangularTable' || table?.tableType == 'RectangularLTable') {

      const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      // TO INCREASE table
      if (table?.tableType == 'RectangularTable') {
        return increasedTables?.length < 2 && canIncrease(table, increasedTablesRight, increasedTablesLeft);
      }
      else {
        // To reduce table
        return table?.tableType == 'RectangularLTable' || increasedTables?.length == 2 && canReduce(table, increasedTablesRight, increasedTablesLeft);
      }
    } else {
      return false;
    }
  }

  const canIncrease = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else return true;
  }

  const canReduce = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else return true;
  }

  const checkCanIncrease = (table) => {
    if (table?.tableType == 'RectangularTable') {
      const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      return canIncrease(table, increasedTablesRight, increasedTablesLeft);
    } else {
      return false;
    }
  }

  const checkCanReduce = (table) => {
    if (table?.tableType == 'RectangularLTable') {
      const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      return canReduce(table, increasedTablesRight, increasedTablesLeft);
    } else {
      return false;
    }
  }

  const canDisableTable = (table) => {
    const listActives = tables.filter(f => f?.active && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF));
    listActives.sort((a, b) => a?.code - b?.code);
    const lastTableActive = listActives?.length > 0
      ? listActives[listActives.length - 1]
      : null;
    return lastTableActive?.code == table?.code ? true : false;
  }

  return (
    <Row gutter={[12, 12]} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>

      {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((table, index) => (
        <GuestTableComponent
          key={index}
          index={index}
          table={table}
          validated={validated}
          roomPlanClosed={roomPlanClosed}
          disableTable={canDisableTable(table)}
          resizable={canResize(table)}
          increasable={checkCanIncrease(table)}
          objectTypes={objectTypes}
          foodRestrictions={foodRestrictions}
          parentActiveTable={parentActiveTable}
          parentChangeTablePurpose={(e) => changeTablePurpose(e, table.code)}
          parentUpdateTable={updateTable}
          parentEditTableCouple={editTableCouple}
          parentSelectToChangeGuests={() => parentSelectToChangeGuests(table.code, table)}
          parentUnselectChange={parentCancelChangeGuests}
          parentOpenImportModal={parentOpenImportModal}
          translate={translate}
          activeLanguage={activeLanguage} />
      ))}
      {(!validated && !roomPlanClosed)
        && <Col id='addTable' xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
          <BtnAddTable>
            <BaseButton
              type='primary'
              icon={'plus'}
              text={translate('ADD_TABLE')}
              loading={addingTable}
              disabled={validated || roomPlanClosed || !showAdd}
              onClick={addTable} />
          </BtnAddTable>
        </Col>}
    </Row>
  );
};

export default React.memo(GuestTablesComponent, (props, nextProps) => {
  if (props.tables == nextProps.tables
    && props?.activeLanguage?.code == nextProps?.activeLanguage?.code
    && props?.isValidated == nextProps?.isValidated
    && props?.roomPlanBlocked == nextProps?.roomPlanBlocked
    && props?.canAddTable == nextProps?.canAddTable) {
    return true;
  }

  // Re-render
  // console.log('re-render tables');
  return false;

});